import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePath } from "src/config/routes";
import { PageTracker } from "src/components/page-tracker";
import { ChaosApolloProvider } from "src/utils/graphql";
import { FeatureFlagsProvider } from "src/utils/feature-flags";
import { useAuth } from "@frontend/ui/auth";
import { AlertsPortalRoutes } from "../../../config/alerts-portal-routes";
import { CCARPageLayout } from "../../../components/layouts";
import { NotPermittedError } from "../../../components/not-permitted-error";
import { CHAOS_LABS_MEMBER_ROLE } from "../../../utils/consts";
import { AlertsTab } from "../tabs/alerts/alerts";
import { WebhooksTab } from "../tabs/webhooks/webhooks";

const replaceHomePath = (path: string) => path.replace(RoutePath.AlertsPortal.Home, "");

const tabs = [
  {
    label: "Alerts",
    value: "alerts",
    path: AlertsPortalRoutes.DefaultPage,
  },
  {
    label: "Webhooks",
    value: "webhooks",
    path: AlertsPortalRoutes.Webhooks,
  },
];

export const CCARAlertsPortalRoutes = PageTracker(() => {
  const auth = useAuth();

  if (!auth.hasRole(CHAOS_LABS_MEMBER_ROLE)) {
    return <NotPermittedError />;
  }
  return (
    <ChaosApolloProvider product="alerts" dontIncludeProtocolHeader>
      <FeatureFlagsProvider>
        <Routes>
          <Route index element={<Navigate to={AlertsPortalRoutes.DefaultPage} replace />} />
          <Route
            path={replaceHomePath(RoutePath.AlertsPortal.Alerts)}
            element={
              <CCARPageLayout type="alerts-portal" tabs={tabs}>
                <AlertsTab />
              </CCARPageLayout>
            }
          />
          <Route
            path={replaceHomePath(RoutePath.AlertsPortal.Webhooks)}
            element={
              <CCARPageLayout type="alerts-portal" tabs={tabs}>
                <WebhooksTab />
              </CCARPageLayout>
            }
          />
          <Route path="*" element={<Navigate to={AlertsPortalRoutes.DefaultPage} replace />} />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
});
