import { Box } from "@frontend/ui";
import { AvsCards } from "./components/cards";
import { AvsTable } from "./components/avs-table";
import { AvsCharts } from "./components/charts/avs-charts";

const Avs = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <AvsCards />
    <AvsTable />
    <AvsCharts />
  </Box>
);

export default Avs;
