import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Header, RenderData } from "@frontend/types";
import { Button } from "@frontend/ui";
import { ChaosTable, FilterPicker, TableAppliedFilters, TableFilter } from "@frontend/ui/chaos-table";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { SortChangeHandler } from "@frontend/ui/hooks/use-pagination";
import { formatAddressCompact, formatAmount } from "@frontend/ui/utils/formatters";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { RouteParams, RoutePath } from "src/config/routes";
import { Client } from "src/pages/ccar-lending-page/types";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useIsInUnifiedProduct } from "src/hooks/use-is-in-unified-product";
import {
  getHealthText,
  getHealthTooltip,
  getRiskLabel,
  getRiskLabelColor,
} from "src/pages/ccar-lending-page/utils/health-utils";
import {
  Chain,
  SortOrder,
  useAssetWalletsLazyQuery,
  useMarketAssetsBreakdownQuery,
  WalletsQuery,
  AssetFragmentFragment,
  useGetMarketWalletsLazyQuery,
  MarketWalletsQuery,
  useAllMarketsQuery,
  ChainHealth,
  Maybe,
} from "../../../generated";

type Props = {
  asset?: string;
  chain?: Chain;
  marketId?: string;
  market?: string;
  marketName?: string;
  tableTitle?: string;
  eModeCategoryId?: number;
};

const pageSize = 10;

const riskLabelTooltip = (riskLabel: string) => {
  if (riskLabel === "Eligible") {
    return "Wallet health score is below liquidation threshold in one market or more";
  }
  if (riskLabel === "At Risk") {
    return "Wallet health is approaching liquidation threshold in one market or more";
  }
  if (riskLabel === "No Risk") {
    return "No risk of liquidation for this wallet";
  }
  return "";
};

const riskOption = (riskName: string) => {
  switch (riskName) {
    case "Bad Debt":
      return -1;
    case "Eligible":
      return 0;
    case "At Risk":
      return 1;
    case "No Risk":
      return 2;
    default:
      return 0;
  }
};

const useUniqueAssets = (filterBy: "hasSupply" | "hasBorrow", assets: AssetFragmentFragment[] = []) =>
  useMemo(
    () =>
      assets
        ? Array.from(
            new Set(
              assets
                .filter((a) => {
                  switch (filterBy) {
                    case "hasBorrow":
                      return a.totalBorrow > 0;
                    case "hasSupply":
                      return a.totalSupply > 0;
                    default:
                      return true;
                  }
                })
                .map((a) => a.asset),
            ),
          )
        : [],
    [assets, filterBy],
  );

const getInitialFilters = (
  supplyTokenOptions: string[],
  borrowTokenOptions: string[],
  asset?: string,
  chains?: Chain[],
  hasEMode?: boolean,
  hasBadDebt?: boolean,
  marketIds?: Maybe<string>[],
  marketId?: string,
  marketType?: string,
) => {
  const filters: FilterPicker[] = [];
  const hasMarketFilter = !marketId && marketIds && marketIds.length > 0 && marketType === "GeneralMarket";
  if (hasEMode) {
    filters.push({
      type: "options",
      options: [{ name: "Show Only E-Mode Wallets", isHidden: false }],
      fieldName: "E-Mode",
      fieldIndex: 4,
      isApplied: false,
    });
  }

  if (!asset) {
    filters.push({
      type: "options",
      options: [
        { name: "No Risk", isHidden: false },
        { name: "At Risk", isHidden: false },
        { name: "Eligible", isHidden: false },
        ...(hasBadDebt ? [{ name: "Bad Debt", isHidden: false }] : []),
      ],
      fieldName: "Liquidation Risk",
      fieldIndex: 5,
      isApplied: false,
    });
  }

  if (chains) {
    filters.push({
      type: "options",
      options: chains.map((o) => ({
        name: o,
        cryptoIcon: wrappedSymbolToIconSymbol(o),
        isHidden: false,
      })),
      fieldName: "Chain",
      fieldIndex: 6,
      isApplied: false,
    });
  }

  if (hasMarketFilter) {
    filters.push({
      type: "options",
      options: marketIds?.map((o) => ({
        name: o ?? "",
        isHidden: false,
      })),
      fieldName: "Market",
      fieldIndex: 7,
      isApplied: false,
    });
  }

  filters.push({
    type: "options",
    options: supplyTokenOptions.map((o) => ({
      name: o,
      cryptoIcon: wrappedSymbolToIconSymbol(o),
      isHidden: true,
    })),
    fieldName: "Supplied Assets",
    fieldIndex: hasMarketFilter ? 8 : 7,
    isApplied: false,
    isAutocomplete: true,
  });

  filters.push({
    type: "options",
    options: borrowTokenOptions.map((o) => ({
      name: o,
      cryptoIcon: wrappedSymbolToIconSymbol(o),
      isHidden: true,
    })),
    fieldName: "Borrowed Assets",
    fieldIndex: hasMarketFilter ? 9 : 8,
    isApplied: false,
    isAutocomplete: true,
  });

  return filters;
};

type MappedWalletType = {
  address: string;
  addressLabel: Maybe<string>;
  totalLiquidityUsd: number;
  totalCollateralUsd: number;
  totalBorrowsUsd: number;
  assetSupply?: Maybe<number>;
  assetBorrows?: Maybe<number>;
  eModeCategoryId: Maybe<number>;
  chainHealth?: ChainHealth[] | null;
  marketHealth?: { market: { id: string }; healthFactor: number }[];
  minRiskStatus?: Maybe<number>;
  badDebtUsd: number;
  chains?: Maybe<string>[];
  marketId?: Maybe<string>;
  riskStatus?: number;
  healthFactor?: Maybe<number>;
  borrowAssets?: Maybe<string>[];
  supplyAssets?: Maybe<string>[];
  collateralAssets?: string[];
  health: Maybe<number>;
  isInSoftLiquidation: boolean | null;
  isEMode: boolean;
};

const WalletsTable = ({
  asset,
  chain,
  marketId,
  market,
  marketName,
  tableTitle = "Wallets",
  eModeCategoryId,
}: Props) => {
  const limit = asset ? 500 : 150;
  const { clientName } = useParams<{ clientName: Client }>();
  const {
    chains,
    wallets: walletConfig,
    isMultichain,
    isMultiMarket,
    hasEMode,
    hasSoftLiquidations,
    eligibleThreshold,
    riskThreshold,
    isHealthPercent,
    marketType,
  } = useClientConfig();
  const sortBy = !walletConfig?.hiddenRows?.liquidity ? "totalLiquidityUSD" : "totalCollateralUSD";
  const shouldFetchAssetBalance =
    asset && !walletConfig?.hiddenRows?.assetSupply && !walletConfig?.hiddenRows?.assetBorrows;
  const [query, setQuery] = useState<WalletsQuery>({
    limit,
    skip: null,
    sort: sortBy,
    order: SortOrder.Descending,
    filter: {
      tokenSymbol: asset ? [asset] : null,
      chain: chain ? [chain] : null,
      marketId: marketId || null,
      market: market || null,
      minRiskStatus: null,
      id: null,
      borrowTokenSymbol: null,
      supplyTokenSymbol: null,
      eModeCategoryId: eModeCategoryId || null,
      eModeToggle: null,
    },
  });

  const [marketWalletsQuery, setMarketWalletsQuery] = useState<MarketWalletsQuery>({
    filter: {
      borrowAssets: null,
      collateralAssets: null,
      eModeCategoryId: eModeCategoryId || null,
      eModeToggle: null,
      id: null,
      marketIds: marketId ? [marketId] : null,
      riskStatus: null,
      chains: chain ? [chain] : null,
    },
    limit,
    skip: null,
    sort: sortBy,
    order: SortOrder.Descending,
  });
  const filterButtonRef = useRef<null | HTMLButtonElement>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [eModeToggleFilter, setEModeToggleFilter] = useState<boolean>();
  const [riskStatusFilter, setRiskStatusFilter] = useState<string[]>();
  const [supplyAssetFilter, setSupplyAssetFilter] = useState<string[]>([]);
  const [borrowAssetFilter, setBorrowAssetFilter] = useState<string[]>([]);
  const [chainFilter, setChainFilter] = useState(chain && [chain]);
  const [marketFilter, setMarketFilter] = useState<string[]>();
  const [resetPagination, setResetPagination] = useState<string>();
  const [loadWallets, { loading: isLoadingWallets }] = useGetMarketWalletsLazyQuery();
  const [loadAssetWallets, { loading: isLoadingAssetWallets }] = useAssetWalletsLazyQuery();
  const { data: marketsData, loading: isLoadingMarkets } = useAllMarketsQuery();
  const [wallets, setWallets] = useState<MappedWalletType[]>();

  useEffect(() => {
    setMarketWalletsQuery((q) => ({
      ...q,
      filter: {
        eModeCategoryId: eModeCategoryId || null,
        eModeToggle: null,
        riskStatus: null,
        borrowAssets: null,
        collateralAssets: null,
        id: null,
        chains: chain ? [chain] : null,
        marketIds: marketId ? [marketId] : null,
      },
    }));
  }, [marketId, eModeCategoryId, chain]);

  useEffect(() => {
    setQuery((q) => ({
      ...q,
      filter: {
        tokenSymbol: asset ? [asset] : null,
        chain: chain ? [chain] : null,
        marketId: marketId || null,
        market: market || null,
        minRiskStatus: null,
        id: null,
        borrowTokenSymbol: null,
        supplyTokenSymbol: null,
        eModeCategoryId: eModeCategoryId || null,
        eModeToggle: null,
      },
    }));
  }, [asset, chain, market, marketId, eModeCategoryId]);

  useEffect(() => {
    setMarketWalletsQuery((q) => ({
      ...q,
      limit,
      sort: sortBy,
      order: SortOrder.Descending,
      filter: {
        marketIds: marketId ? [marketId] : null,
        eModeCategoryId: eModeCategoryId || null,
        eModeToggle: null,
        riskStatus: null,
        id: null,
        borrowAssets: null,
        collateralAssets: null,
        chains: chain ? [chain] : null,
      },
    }));
  }, [limit, sortBy, marketId, eModeCategoryId, chain]);

  useEffect(() => {
    if (asset) {
      void loadAssetWallets({
        variables: { query, shouldFetchAssetBalance: !!shouldFetchAssetBalance },
      }).then(({ data }) =>
        setWallets((ws) => {
          if (data?.assetWallets) {
            const mappedWallets = data.assetWallets.map((w) => ({
              isEMode: !!w.eModeCategoryId && w.eModeCategoryId !== 0,
              isInSoftLiquidation: w.isInSoftLiquidation,
              totalLiquidityUsd: w.totalLiquidityUsd,
              totalCollateralUsd: w.totalCollateralUsd,
              assetSupply: w.assetSupply,
              assetBorrows: w.assetBorrows,
              assetCollateralUsd: w.assetCollateralUsd,
              assetBorrowsUsd: w.assetBorrowsUsd,
              totalBorrowsUsd: w.totalBorrowsUsd,
              eModeCategoryId: w.eModeCategoryId,
              address: w.address,
              chainHealth: w.chainHealth,
              marketHealth: w.marketHealth,
              health: w.health,
              addressLabel: w.addressLabel,
              chains: w.chains,
              borrowAssets: w.borrowAssets,
              minRiskStatus: w.minRiskStatus,
              badDebtUsd: w.badDebtUsd,
            }));
            return query.skip ? [...(ws || []), ...mappedWallets] : mappedWallets;
          }

          return ws;
        }),
      );
    } else {
      void loadWallets({
        variables: { query: marketWalletsQuery },
      }).then(({ data }) =>
        setWallets((ws) => {
          if (data?.marketWallets) {
            const mappedWallets = data.marketWallets.map((w) => ({
              ...w,
              health: w.healthFactor,
              isEMode: w.eModeCategoryId !== 0,
              badDebtUsd: w.badDebtUsd ?? 0,
              chains: [w.chain],
            }));
            return query.skip ? [...(ws || []), ...mappedWallets] : mappedWallets;
          }

          return ws;
        }),
      );
    }
  }, [query, marketWalletsQuery, asset, loadAssetWallets, loadWallets, shouldFetchAssetBalance]);

  const { data: assetsResponse } = useMarketAssetsBreakdownQuery();
  const assets = assetsResponse?.assetsBreakdown;
  const supplyTokenOptions = useUniqueAssets("hasSupply", assets);
  const borrowTokenOptions = useUniqueAssets("hasBorrow", assets);
  const marketIds = marketsData?.allMarkets.map((m) => m.id);
  const { isInUnifiedProduct } = useIsInUnifiedProduct();
  const badDebtHidden = !!walletConfig?.hiddenRows?.badDebt;
  const filters: FilterPicker[] | undefined = useMemo(
    () =>
      supplyTokenOptions?.length && borrowTokenOptions?.length
        ? getInitialFilters(
            supplyTokenOptions,
            borrowTokenOptions,
            asset,
            !chain && chains.length > 1 ? chains : undefined,
            hasEMode && !eModeCategoryId,
            !badDebtHidden,
            marketIds,
            marketId,
            marketType,
          )
        : undefined,
    [
      supplyTokenOptions,
      borrowTokenOptions,
      asset,
      chain,
      chains,
      hasEMode,
      eModeCategoryId,
      badDebtHidden,
      marketIds,
      marketId,
      marketType,
    ],
  );
  const appliedFilters = filters?.map((f) => {
    const options = f.options.map((o) => ({
      ...o,
      isHidden:
        (f.fieldName === "E-Mode" && !eModeToggleFilter) ||
        (f.fieldName === "Liquidation Risk" && !riskStatusFilter?.includes(o.name)) ||
        (f.fieldName === "Chain" && !chainFilter?.includes(o.name as Chain)) ||
        (f.fieldName === "Supplied Assets" && !supplyAssetFilter.includes(o.name)) ||
        (f.fieldName === "Borrowed Assets" && !borrowAssetFilter.includes(o.name)) ||
        (f.fieldName === "Market" && !marketFilter?.includes(o.name)),
    }));

    return {
      ...f,
      isApplied: !!options.find(
        (o) =>
          (f.fieldName === "E-Mode" && eModeToggleFilter && o.isHidden) ||
          (f.fieldName === "Liquidation Risk" && riskStatusFilter?.length && o.isHidden) ||
          (f.fieldName === "Chain" && chainFilter?.length && o.isHidden) ||
          (f.fieldName === "Supplied Assets" && supplyAssetFilter.length && o.isHidden) ||
          (f.fieldName === "Borrowed Assets" && borrowAssetFilter.length && o.isHidden) ||
          (f.fieldName === "Market" && marketFilter?.length && o.isHidden),
      ),
      options,
    };
  });
  const onSortChange: SortChangeHandler = useCallback((sort, order) => {
    setQuery((q) =>
      q.sort === sort &&
      ((q.order === SortOrder.Ascending && order === 1) || (q.order === SortOrder.Descending && order === -1))
        ? q
        : {
            ...q,
            skip: null,
            sort,
            order: order === 1 ? SortOrder.Ascending : SortOrder.Descending,
          },
    );
    setMarketWalletsQuery((q) =>
      q.sort === sort &&
      ((q.order === SortOrder.Ascending && order === 1) || (q.order === SortOrder.Descending && order === -1))
        ? q
        : {
            ...q,
            skip: null,
            sort,
            order: order === 1 ? SortOrder.Ascending : SortOrder.Descending,
          },
    );
  }, []);
  useEffect(() => {
    setQuery((q) => {
      if (!riskStatusFilter && !supplyAssetFilter) {
        return q;
      }

      setResetPagination(uuidv4());

      return {
        ...q,
        skip: null,
        filter: {
          minRiskStatus: riskStatusFilter?.length ? riskStatusFilter.map((o) => riskOption(o)) : null,
          tokenSymbol: q.filter?.tokenSymbol || null,
          supplyTokenSymbol: supplyAssetFilter?.length ? supplyAssetFilter : null,
          borrowTokenSymbol: borrowAssetFilter?.length ? borrowAssetFilter : null,
          chain: chainFilter?.length ? chainFilter : q.filter?.chain || null,
          marketId: q.filter?.marketId || null,
          market: q.filter?.market || null,
          id: q.filter?.id || null,
          eModeToggle: eModeToggleFilter || null,
          eModeCategoryId: eModeCategoryId || null,
        },
      };
    });
    setMarketWalletsQuery((q) => {
      if (!riskStatusFilter && !supplyAssetFilter) {
        return q;
      }

      setResetPagination(uuidv4());

      return {
        ...q,
        skip: null,
        filter: {
          riskStatus: riskStatusFilter?.length ? riskStatusFilter.map((o) => riskOption(o)) : null,
          collateralAssets: supplyAssetFilter?.length ? supplyAssetFilter : null,
          borrowAssets: borrowAssetFilter?.length ? borrowAssetFilter : null,
          chains: chainFilter?.length ? chainFilter : null,
          marketIds: marketFilter?.length ? marketFilter : null,
          id: q.filter?.id || null,
          eModeToggle: eModeToggleFilter || null,
          eModeCategoryId: eModeCategoryId || null,
        },
      };
    });
  }, [
    riskStatusFilter,
    chainFilter,
    supplyAssetFilter,
    borrowAssetFilter,
    eModeToggleFilter,
    eModeCategoryId,
    marketFilter,
  ]);
  const onPageChange = useCallback(
    (page) => {
      if (wallets && pageSize * page === wallets.length) {
        setQuery((q) => ({ ...q, skip: wallets.length }));
        setMarketWalletsQuery((q) => ({ ...q, skip: wallets.length }));
      }
    },
    [wallets],
  );
  const onSearch = useCallback(
    (text: string) => {
      setQuery((q) => ({
        ...q,
        skip: null,
        filter: {
          id: text ? text.toLowerCase() : null,
          chain: q.filter?.chain || null,
          marketId: q.filter?.marketId || null,
          market: q.filter?.market || null,
          minRiskStatus: q.filter?.minRiskStatus || null,
          tokenSymbol: q.filter?.tokenSymbol || null,
          supplyTokenSymbol: q.filter?.supplyTokenSymbol || null,
          borrowTokenSymbol: q.filter?.borrowTokenSymbol || null,
          eModeCategoryId: eModeCategoryId || null,
          eModeToggle: null,
        },
      }));
      setMarketWalletsQuery((q) => ({
        ...q,
        skip: null,
        filter: {
          id: text ? text.toLowerCase() : null,
          chains: q.filter?.chains || null,
          marketIds: q.filter?.marketIds || null,
          riskStatus: q.filter?.riskStatus || null,
          collateralAssets: q.filter?.collateralAssets || null,
          borrowAssets: q.filter?.borrowAssets || null,
          eModeCategoryId: eModeCategoryId || null,
          eModeToggle: null,
        },
      }));
    },
    [eModeCategoryId],
  );
  const walletsHandleOnClickRow = (rowIdx: number) => {
    const walletId = wallets?.[rowIdx]?.address;

    return (isInUnifiedProduct ? RoutePath.Risk.WalletDetails : RoutePath.CCARLending.WalletDetails)
      .replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.Address, walletId || "");
  };

  const walletsTableHeaders: Header[] = useMemo(
    () =>
      [
        {
          renderType: "TEXT",
          text: "Wallet",
          field: "id",
          width: "7%",
        },
        ...(!walletConfig?.hiddenRows?.liquidity
          ? [
              {
                renderType: "TEXT",
                text: walletConfig?.customTitles?.liquidity || "Total Supply",
                field: "totalLiquidityUSD",
                width: "7%",
                tooltip: "Sum of borrow and collateral assets provided to the protocol by this wallet",
              },
            ]
          : []),
        ...(!walletConfig?.hiddenRows?.totalCollateral
          ? [
              {
                renderType: "TEXT",
                text: walletConfig?.customTitles?.collateral || "Total Collateral",
                field: "totalCollateralUSD",
                width: "7%",
              },
            ]
          : []),
        {
          renderType: "TEXT",
          text: "Total Borrow",
          field: "totalBorrowsUSD",
          width: "7%",
        },
        ...(asset && !walletConfig?.hiddenRows?.assetSupply
          ? [
              {
                renderType: "TEXT",
                text: `${asset} Supply`,
                field: "asset.underlyingBalanceUSD",
                width: "7%",
              },
            ]
          : []),
        ...(asset && !walletConfig?.hiddenRows?.assetBorrows
          ? [
              {
                renderType: "TEXT",
                text: `${asset} Borrow`,
                field: "asset.totalBorrowsUSD",
                width: "7%",
              },
            ]
          : []),
        ...(hasSoftLiquidations
          ? [
              {
                renderType: "TEXT",
                text: "Status",
                field: "isInSoftLiquidation",
                width: "7%",
              },
            ]
          : []),
        ...(hasEMode && !eModeCategoryId
          ? [
              {
                renderType: "TEXT",
                text: "E-Mode",
                field: "eModeCategoryId",
                width: "7%",
                nonSortable: !(marketId || chain || (!isMultichain && !isMultiMarket)),
              },
            ]
          : []),
        ...(marketId || chain || (!isMultichain && !isMultiMarket)
          ? [
              {
                renderType: "TEXT",
                text: "Health",
                field: asset ? "healthFactor" : "marketHealth.healthFactor",
                width: "7%",
              },
            ]
          : [
              {
                renderType: "TEXT",
                text: "Liquidation Risk",
                field: "minRiskStatus",
                width: "7%",
              },
            ]),
        ...(!walletConfig?.hiddenRows?.badDebt
          ? [
              {
                renderType: "TEXT",
                text: "Bad Debt",
                field: "badDebtUSD",
                width: "7%",
              },
            ]
          : []),
        ...(!marketId && !chain && chains.length > 1 && isMultichain
          ? [
              {
                renderType: "TEXT",
                text: "Chain",
                nonSortable: true,
                width: "3%",
              },
            ]
          : []),
        ...(isMultiMarket && marketType === "GeneralMarket" && !marketId
          ? [
              {
                renderType: "TEXT",
                text: "Market",
                nonSortable: true,
                width: "10%",
              },
            ]
          : []),
        {
          renderType: "ICONS",
          text: "Supplied Assets",
          nonSortable: true,
          width: "7%",
        },
        {
          renderType: "ICONS",
          text: "Borrowed Assets",
          nonSortable: true,
          width: "7%",
        },
      ] as Header[],
    [
      walletConfig?.hiddenRows?.liquidity,
      walletConfig?.hiddenRows?.totalCollateral,
      walletConfig?.hiddenRows?.assetSupply,
      walletConfig?.hiddenRows?.assetBorrows,
      walletConfig?.hiddenRows?.badDebt,
      walletConfig?.customTitles?.liquidity,
      walletConfig?.customTitles?.collateral,
      asset,
      hasSoftLiquidations,
      hasEMode,
      eModeCategoryId,
      marketId,
      chain,
      isMultichain,
      isMultiMarket,
      chains.length,
      marketType,
    ],
  );

  const walletsTableRows: RenderData[][] = useMemo(
    () =>
      (wallets || []).map((wallet) => {
        const singleMarket = marketId || wallet.marketId || chain || (chains.length === 1 && chains[0]);
        const walletMarketName = marketsData?.allMarkets.find((m) => m.id === wallet.marketId)?.name || marketName;
        let health: number | null | undefined;
        let minRiskStatus: number;

        if (wallet.marketId) {
          health = wallet.healthFactor;
          minRiskStatus = wallet.riskStatus ?? 0;
        } else {
          const singleMarketHealthFactor = singleMarket
            ? wallet.marketHealth?.find((mh) => mh.market.id === singleMarket)?.healthFactor
            : undefined;
          health = singleMarket
            ? singleMarketHealthFactor !== undefined
              ? singleMarketHealthFactor
              : wallet.health
            : undefined;
          minRiskStatus = badDebtHidden ? Math.max(wallet.minRiskStatus || 0, 0) : wallet.minRiskStatus ?? 0;
        }
        return [
          {
            renderType: "TEXT",
            text: wallet.addressLabel || formatAddressCompact(wallet.address),
            tooltipText: wallet.address,
            searchTerm: wallet.address,
            copyText: wallet?.address,
            exportText: wallet?.address,
          },
          ...(!walletConfig?.hiddenRows?.liquidity
            ? [
                {
                  renderType: "TEXT",
                  text: formatAmount(wallet.totalLiquidityUsd || 0, { currency: "USD", maximumFractionDigits: 2 }),
                  tooltipText: formatAmount(wallet.totalLiquidityUsd, { currency: "USD", notation: "standard" }),
                  value: Number(wallet.totalLiquidityUsd) || 0,
                },
              ]
            : []),
          ...(!walletConfig?.hiddenRows?.totalCollateral
            ? [
                {
                  renderType: "TEXT",
                  text: formatAmount(wallet.totalCollateralUsd, { currency: "USD", maximumFractionDigits: 2 }),
                  tooltipText: formatAmount(wallet.totalCollateralUsd, { currency: "USD", notation: "standard" }),
                  value: Number(wallet.totalCollateralUsd) || 0,
                },
              ]
            : []),
          {
            renderType: "TEXT",
            text: wallet.totalBorrowsUsd
              ? formatAmount(wallet.totalBorrowsUsd, { currency: "USD", maximumFractionDigits: 2 })
              : "-",
            tooltipText: wallet.totalBorrowsUsd
              ? formatAmount(wallet.totalBorrowsUsd, { currency: "USD", notation: "standard" })
              : undefined,
            value: Number(wallet.totalBorrowsUsd) || 0,
          },
          ...(asset && !walletConfig?.hiddenRows?.assetSupply
            ? [
                {
                  renderType: "TEXT",
                  text: wallet.assetSupply ? formatAmount(wallet.assetSupply ?? 0, { maximumFractionDigits: 2 }) : "-",
                  tooltipText: wallet.assetSupply
                    ? formatAmount(wallet.assetSupply ?? 0, { notation: "standard" })
                    : undefined,
                  value: Number(wallet.assetSupply) || 0,
                },
              ]
            : []),
          ...(asset && !walletConfig?.hiddenRows?.assetBorrows
            ? [
                {
                  renderType: "TEXT",
                  text: wallet.assetBorrows
                    ? formatAmount(wallet.assetBorrows ?? 0, { maximumFractionDigits: 2 })
                    : "-",
                  tooltipText: wallet.assetBorrows
                    ? formatAmount(wallet.assetBorrows ?? 0, { notation: "standard" })
                    : undefined,
                  value: Number(wallet.assetBorrows) || 0,
                },
              ]
            : []),
          ...(hasSoftLiquidations
            ? [
                {
                  renderType: "TEXT",
                  status: wallet.isInSoftLiquidation ? "WARNING" : "RECOMMENDED",
                  text: wallet.isInSoftLiquidation ? "Soft Liq." : "Healthy",
                },
              ]
            : []),
          ...(hasEMode && !eModeCategoryId
            ? [
                {
                  renderType: "TEXT",
                  status: wallet.isEMode ? "RECOMMENDED" : "WARNING",
                  text: wallet.isEMode ? "E-Mode" : "Normal",
                },
              ]
            : []),
          ...((marketId || chain || (!isMultichain && !isMultiMarket)) && health !== undefined && health !== null
            ? [
                {
                  renderType: "TEXT",
                  text: getHealthText(health, isHealthPercent),
                  value: health === Number.MAX_VALUE ? 101 : Math.min(health, 100),
                  isValueExcluded: health === Number.MAX_VALUE,
                  status: health < riskThreshold ? (health < eligibleThreshold ? "ERROR" : "WARNING") : "INFO",
                  width: "70px",
                  tooltipText: getHealthTooltip(health, isHealthPercent),
                },
              ]
            : [
                {
                  renderType: "CHIP",
                  text: getRiskLabel(minRiskStatus),
                  value: getRiskLabel(minRiskStatus),
                  chipColor: getRiskLabelColor(minRiskStatus),
                  tooltipText: riskLabelTooltip(getRiskLabel(minRiskStatus)),
                },
              ]),
          ...(!walletConfig?.hiddenRows?.badDebt
            ? [
                {
                  renderType: "TEXT",
                  text: formatAmount(wallet.badDebtUsd ?? 0, { currency: "USD", maximumFractionDigits: 2 }),
                  tooltipText: formatAmount(wallet.badDebtUsd ?? 0, { currency: "USD", notation: "standard" }),
                  value: Number(wallet.badDebtUsd) || 0,
                },
              ]
            : []),
          ...(!marketId && !chain && chains.length > 1 && isMultichain
            ? [
                {
                  renderType: "ICONS",
                  text: "",
                  icons: wallet.chains,
                },
              ]
            : []),
          ...(isMultiMarket && marketType === "GeneralMarket" && !marketId
            ? [
                {
                  renderType: "TEXT",
                  text: walletMarketName,
                  tooltipText: walletMarketName,
                },
              ]
            : []),
          {
            renderType: "ICONS",
            text: "Supplied Assets",
            icons: wallet.supplyAssets,
          },
          {
            renderType: "ICONS",
            text: "Borrowed Assets",
            icons: wallet.borrowAssets,
          },
        ] as RenderData[];
      }),
    [
      wallets,
      marketId,
      chain,
      chains,
      marketsData?.allMarkets,
      marketName,
      walletConfig?.hiddenRows?.liquidity,
      walletConfig?.hiddenRows?.totalCollateral,
      walletConfig?.hiddenRows?.assetSupply,
      walletConfig?.hiddenRows?.assetBorrows,
      walletConfig?.hiddenRows?.badDebt,
      asset,
      hasSoftLiquidations,
      hasEMode,
      eModeCategoryId,
      isMultichain,
      isMultiMarket,
      isHealthPercent,
      riskThreshold,
      eligibleThreshold,
      marketType,
      badDebtHidden,
    ],
  );

  const initialSortIndex = walletsTableHeaders.findIndex((header) => header.field === sortBy) || 0;
  const metadata = chain || asset ? `${chain ? `${chain} ` : ""}${asset || ""}` : undefined;
  return (
    <>
      <ChaosTable
        title={tableTitle}
        description={
          asset
            ? `Wallets that have open borrow or supply positions
           of ${asset} ${marketName ? `on the ${marketName} market` : ""}.`
            : undefined
        }
        headers={walletsTableHeaders}
        data={walletsTableRows}
        metadata={metadata}
        pageSize={pageSize}
        isInitialSortEnable
        initialSortBy={initialSortIndex}
        isFullHeight={!chain}
        isInitialSortDesc
        rowHref={walletsHandleOnClickRow}
        rowHrefTarget={!isInUnifiedProduct ? "_blank" : undefined}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        isLoading={(asset ? isLoadingAssetWallets : isLoadingWallets) || isLoadingMarkets}
        isFilterHidden
        showSearch
        serchbarPlaceholder="Search for Wallet"
        onSearch={onSearch}
        emptyState={{
          title: "No wallets found",
          icon: "wallet-icon",
        }}
        customFilter={
          <>
            {filters && (
              <TableAppliedFilters
                filters={appliedFilters || []}
                removeFilter={(i) => {
                  if (filters[i].fieldName === "E-Mode") {
                    setEModeToggleFilter(undefined);
                  } else if (filters[i].fieldName === "Liquidation Risk") {
                    setRiskStatusFilter([]);
                  } else if (filters[i].fieldName === "Chain") {
                    setChainFilter([]);
                  } else if (filters[i].fieldName === "Supplied Assets") {
                    setSupplyAssetFilter([]);
                  } else if (filters[i].fieldName === "Borrowed Assets") {
                    setBorrowAssetFilter([]);
                  } else if (filters[i].fieldName === "Market") {
                    setMarketFilter([]);
                  }
                }}
                headers={(appliedFilters || []).map((f) => ({ renderType: "TEXT", text: f.fieldName }))}
              />
            )}
            <Button
              color="secondary"
              ref={filterButtonRef}
              disabled={!filters}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              aria-label="Add Filter"
              data-testid="toggle-filter"
              startIcon={<CustomIcon icon="filter" />}
            >
              Filter
            </Button>
          </>
        }
        showRowChevron
        resetPagination={resetPagination}
      />
      {appliedFilters && (
        <TableFilter
          isOpen={isFilterOpen}
          anchorEl={filterButtonRef.current}
          filters={appliedFilters}
          onChange={(filterValues) => {
            Object.values(filterValues)
              .filter(Boolean)
              .forEach((f) => {
                if (f.fieldName === "E-Mode") {
                  setEModeToggleFilter(!!(f as FilterPicker).options.find((o) => !o.isHidden));
                } else if (f.fieldName === "Liquidation Risk") {
                  setRiskStatusFilter((f as FilterPicker).options.filter((o) => !o.isHidden).map((o) => o.name));
                } else if (f.fieldName === "Chain") {
                  setChainFilter((f as FilterPicker).options.filter((o) => !o.isHidden).map((o) => o.name as Chain));
                } else if (f.fieldName === "Supplied Assets") {
                  setSupplyAssetFilter((f as FilterPicker).options.filter((o) => !o.isHidden).map((o) => o.name));
                } else if (f.fieldName === "Borrowed Assets") {
                  setBorrowAssetFilter((f as FilterPicker).options.filter((o) => !o.isHidden).map((o) => o.name));
                } else if (f.fieldName === "Market") {
                  setMarketFilter((f as FilterPicker).options.filter((o) => !o.isHidden).map((o) => o.name));
                }
              });
          }}
          close={() => setIsFilterOpen(false)}
        />
      )}
    </>
  );
};

export default WalletsTable;
