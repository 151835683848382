import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { Box, Grid, Paper } from "@frontend/ui";
import TvlChart from "./tvl-chart";
import CollateralBreakdownChart from "./collateral-breakdown-chart";
import CollateralBreakdownPieChart from "./collateral-breakdown-pie-chart";
import CollateralInflowOutflowChart from "./collateral-inflow-outflow-chart";
import AprChart from "./apr-chart";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";
import EethPegChart from "./eeth-peg-chart";

const OverviewCharts = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Quarter);

  return (
    <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box marginLeft="auto">
        <TimeSpanPicker selectedTimeSpan={timeSpan} onSelectTimeSpan={setTimeSpan} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="widget">
            <TvlChart timeSpan={timeSpan} />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper variant="widget">
            <CollateralBreakdownPieChart />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper variant="widget">
            <CollateralBreakdownChart timeSpan={timeSpan} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="widget">
            <AprChart timeSpan={timeSpan} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="widget">
            <EethPegChart timeSpan={timeSpan} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="widget">
            <CollateralInflowOutflowChart timeSpan={timeSpan} />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OverviewCharts;
