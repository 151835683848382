import { formatDuration } from "@frontend/ui/utils/formatters";
import { getDeltaFields } from "@frontend/ui/trend";
import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useWithdrawalsOverviewQuery } from "../../../generated";

const WithdrawalsCards = () => {
  const { data } = useWithdrawalsOverviewQuery();

  const {
    liquidityPoolTvlEth,
    prevLiquidityPoolTvlEth,
    withdrawalQueueSize,
    prevWithdrawalQueueSize,
    withdrawalQueueWaitingTimeSeconds,
    prevWithdrawalQueueWaitingTimeSeconds,
  } = data?.withdrawalsOverview || {};

  const cards: ValueCardProps[] = [
    {
      title: "Liquidity Pool Size",
      value: liquidityPoolTvlEth,
      icon: "pie-slice",
      cryptoCurrency: "ETH",
      ...getDeltaFields(liquidityPoolTvlEth, prevLiquidityPoolTvlEth || undefined),
    },
    {
      title: "Withdrawal Queue Length",
      value: withdrawalQueueSize,
      icon: "users",
      cryptoCurrency: "ETH",
      ...getDeltaFields(withdrawalQueueSize, prevWithdrawalQueueSize || undefined, false),
    },
    {
      title: "Withdrawal Queue Waiting Time",
      value: withdrawalQueueWaitingTimeSeconds
        ? formatDuration({
            milliseconds: withdrawalQueueWaitingTimeSeconds * 1000,
            compactHours: 24,
            showSeconds: false,
          })
        : undefined,
      icon: "clock",
      ...getDeltaFields(withdrawalQueueWaitingTimeSeconds, prevWithdrawalQueueWaitingTimeSeconds || undefined, false),
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default WithdrawalsCards;
