import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useParams } from "react-router-dom";
import { useAvsDataQuery } from "../../../generated";

const AvsCards = () => {
  const { avsId } = useParams();
  const { data } = useAvsDataQuery();

  const selectedAvsData = data?.avsData?.find((d) => d.id === avsId);
  const etherfiTvr = selectedAvsData?.protocols.find((p) => p.protocol === "EtherFi");

  const cards: ValueCardProps[] = [
    {
      title: "TVR",
      value: etherfiTvr?.delegationEth,
      icon: "coins-stack-icon",
      cryptoCurrency: "ETH",
    },
    {
      title: "Number of Operators Delegated by EtherFi",
      value: selectedAvsData?.operators?.length,
      icon: "users",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default AvsCards;
