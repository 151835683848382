import { ReactNode } from "react";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { RoutePath } from "../../../../config/routes";
import { RouteParams } from "../../../../config/route-params";
import { CommunityDashboardLayout } from "../../../../components/layouts";
import AvsDropdownHeader from "./avs-dropdown-header";
import NodeOperatorDropdownHeader from "./node-operators-dropdown-header";

type Props = {
  children: ReactNode;
};

const StakingTabsLayout = ({ children }: Props) => {
  const { clientName } = useParams();
  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);
  return (
    <CommunityDashboardLayout
      showClientToolsDropdown={false}
      tabs={[
        {
          label: "Overview",
          path: replaceParams(RoutePath.Risk.Overview),
        },
        {
          label: "Withdrawals",
          path: replaceParams(RoutePath.Risk.Withdrawals),
        },
        {
          label: "AVS",
          path: replaceParams(RoutePath.Risk.Avs),
        },
        {
          label: "Node Operators",
          path: replaceParams(RoutePath.Risk.NodeOperators),
        },
      ]}
    >
      {children}
    </CommunityDashboardLayout>
  );
};

export const renderTabsLayout = (children: ReactNode) => <StakingTabsLayout>{children}</StakingTabsLayout>;

export const renderAvsPageLayout = (children: ReactNode) => (
  <StakingTabsLayout>
    <Box display="flex" flexDirection="column">
      <AvsDropdownHeader />
      {children}
    </Box>
  </StakingTabsLayout>
);

export const renderNodeOperatorPageLayout = (children: ReactNode) => (
  <StakingTabsLayout>
    <Box display="flex" flexDirection="column">
      <NodeOperatorDropdownHeader />
      {children}
    </Box>
  </StakingTabsLayout>
);
