import { CCARLendingRoutes } from "./ccar-lending-routes";
import { RiskMonitoringRoutes } from "./risk-monitoring-routes";
import { ParameterRecommendationsRoutes } from "./parameter-recommendations-routes";
import { RouteParams } from "./route-params";
import { OracleDashboardRoutes } from "./oracle-portal-routes";
import { AlertsPortalRoutes } from "./alerts-portal-routes";

const CommunityAnalyticsHome = `/${RouteParams.ClientName}`;
const DydxParamRecommendationsHome = `/${RouteParams.ClientName}/param-recommendations`;
const CCARPerpsHome = `/${RouteParams.ClientName}/${window.location.pathname.match("risk") ? "risk" : "ccar-perps"}`;
const LpDashboardHome = `/${RouteParams.ClientName}/lp-dashboard`;
const IncentivesOptimizationHome = `/${RouteParams.ClientName}/incentives-optimization`;
const TwapHome = `/${RouteParams.ClientName}/twap`;
const LpStrategyCalculatorHome = `/${RouteParams.ClientName}/lp-strategy-calculator`;

export const BenqiRoutePath = {
  BenqiCalculator: `/${RouteParams.ClientName}/veqi-calculator`,
  BenqiReport: `/${RouteParams.ClientName}/report`,
};

export const DydxRoutePath = {
  PerpetualFundingRate: `/${RouteParams.ClientName}/perpetual-funding-rate`,
  Calculator: `/${RouteParams.ClientName}/calculator`,
  DydxParamRecommendations: {
    Home: DydxParamRecommendationsHome,
    Market: `${DydxParamRecommendationsHome}/markets/${RouteParams.Market}`,
    Methodology: `${DydxParamRecommendationsHome}/methodology`,
  },
};

export const OsmosisRoutePath = {
  IncentivesOptimization: {
    Home: IncentivesOptimizationHome,
    Methodology: `${IncentivesOptimizationHome}/methodology`,
    Pool: `${IncentivesOptimizationHome}/pools/${RouteParams.PoolId}`,
  },
  LpDashboard: {
    Home: LpDashboardHome,
    Pools: `${LpDashboardHome}/pools`,
    PoolsOverview: `${LpDashboardHome}/pools/overview`,
    PoolsAll: `${LpDashboardHome}/pools/all`,
    PoolDetails: `${LpDashboardHome}/pools/${RouteParams.PoolId}`,
    WalletDetails: `${LpDashboardHome}/wallets/${RouteParams.Address}`,
  },
};

export const UniswapRoutePath = {
  Twap: {
    Home: TwapHome,
    Methodology: `${TwapHome}/methodology`,
    PoolDetails: `${TwapHome}/pools/${RouteParams.Chain}/${RouteParams.PoolId}`,
  },
  LpStrategyCalculator: {
    Home: LpStrategyCalculatorHome,
    UnderReview: `${LpStrategyCalculatorHome}/under-review`,
  },
};

const Markets = `${CCARPerpsHome}/markets`;
const MarketDetails = `${Markets}/${RouteParams.MarketId}`;
const MarketDetailsOverview = `${Markets}/${RouteParams.MarketId}/overview`;
const MarketDetailsPool = `${Markets}/${RouteParams.MarketId}/pool`;
const AccountPositions = `${CCARPerpsHome}/positions/${RouteParams.Address}`;

export const RoutePath = {
  Privacy: "/privacy",
  Terms: "/terms",
  AuthCallback: "/auth/callback",
  CommunityAnalytics: {
    Home: CommunityAnalyticsHome,
    Private: CommunityAnalyticsHome,
    Public: `${CommunityAnalyticsHome}/${RouteParams.DashboardId}`,
  },
  ParameterRecommendations: ParameterRecommendationsRoutes,
  CCARLending: CCARLendingRoutes,
  Risk: RiskMonitoringRoutes,
  OraclePortal: OracleDashboardRoutes,
  AlertsPortal: AlertsPortalRoutes,
  CCARPerpetuals: {
    Home: CCARPerpsHome,
    Overview: `${CCARPerpsHome}/overview`,
    Markets: `${CCARPerpsHome}/markets`,
    MarketDetails,
    MarketDetailsOverview,
    MarketDetailsPool,
    Positions: `${CCARPerpsHome}/positions`,
    AccountPositions,
    SubAccountPositions: `${AccountPositions}/subAccount/${RouteParams.SubAccount}`,
    Liquidations: `${CCARPerpsHome}/liquidations`,
    RiskExplorer: `${CCARPerpsHome}/risk-explorer`,
    Alerts: `${CCARPerpsHome}/alerts`,
    JlpPool: `${CCARPerpsHome}/jlp-pool`,
  },
  ...BenqiRoutePath,
  ...DydxRoutePath,
  ...OsmosisRoutePath,
  ...UniswapRoutePath,
};

export { RouteParams } from "./route-params";
