import { FC } from "react";
import { Box, TabProps } from "@frontend/ui";
import { PublicFooter } from "src/components/public-footer";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { CommunityDashboardHeader } from "./community-dashboard-header";
import TabsNavigation from "./tabs-navigation";

type Props = {
  type: "lending" | "perpetuals" | "risk" | "alerts-portal";
  tabs?: TabProps[];
};

export const CCARPageLayout: FC<Props> = ({ children, type, tabs }) => {
  const { clientName } = useParams<{ clientName: string }>();

  let homePath = "";
  switch (type) {
    case "lending":
      homePath = RoutePath.CCARLending.Overview.replace(RouteParams.ClientName, clientName!);
      break;
    case "perpetuals":
      homePath = RoutePath.CCARPerpetuals.Overview.replace(RouteParams.ClientName, clientName!);
      break;
    case "risk":
      homePath = RoutePath.Risk.Overview.replace(RouteParams.ClientName, clientName!);
      break;
    case "alerts-portal":
      homePath = RoutePath.AlertsPortal.Alerts;
      break;
    default:
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column" alignItems="center">
      <CommunityDashboardHeader homePath={homePath} />
      {tabs && (
        <Box
          px={{
            xs: 2,
            sm: 5,
            md: 10,
            lg: 15,
          }}
          bgcolor="#0C0D10"
          position="sticky"
          top={0}
          zIndex={10}
          width="100%"
        >
          <Box maxWidth={1320} margin="auto">
            {tabs && <TabsNavigation tabs={tabs} />}
          </Box>
        </Box>
      )}
      <Box
        flex={1}
        py={{
          xs: 3,
          md: 5,
        }}
        px={{
          xs: 2,
          sm: 5,
          md: 10,
          lg: 15,
        }}
        margin="auto"
        maxWidth={1560}
        width="100%"
      >
        {children}
      </Box>
      <PublicFooter />
    </Box>
  );
};
