import { Box, Button, CryptoIcon, CustomReactSelect, Drawer, Grid, Typography } from "@frontend/ui";
import { formatDateAndTime } from "@frontend/ui/utils/formatters";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TagChip } from "@frontend/ui/tag-chip";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { AlertFragment, AlertSeverity, RiskAlertQueryVariables, useRiskAlertQuery } from "../../generated";
import JiraLogo from "../../../../assets/img/Jira.svg";
import { useAlertWebhooks } from "../../hooks/useWebhooks";
import { alertSeverityLabels } from "../../../../utils/consts";
import { CcarClient, mapProtocolToData } from "./utils";
import { AlertWebhook } from "../webhooks/utils";
import { dataFetchersURL } from "../../../../utils/data-fetchers-url";

export const AlertDrawer = () => {
  const { alertId = "" } = useParams<"alertId">();
  const [query, setQuery] = useState<{ variables: RiskAlertQueryVariables }>({
    variables: {
      id: alertId,
    },
  });

  const { data: webhooks, loading: loadingWebhooks } = useAlertWebhooks();
  const [selectedWebhook, setSelectedWebhook] = useState<string | null>(null);
  const [webhookSending, setWebhookSending] = useState(false);

  useEffect(() => {
    if (!alertId) {
      return;
    }
    setQuery({
      variables: {
        id: alertId,
      },
    });
  }, [alertId]);

  const { data, loading } = useRiskAlertQuery(query);
  const alert: AlertFragment | undefined | null = data?.internalRiskAlert;
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(alert !== null);

  useEffect(() => {
    if (loading) {
      return;
    }
    setOpen(true);
  }, [loading]);

  const handleSendWebhook = useCallback(() => {
    if (!selectedWebhook) {
      return;
    }

    setWebhookSending(true);

    void axios
      .post<AlertWebhook>(
        `${dataFetchersURL()}data/webhooks/send`,
        {
          name: selectedWebhook,
          alertId,
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          console.log("Error sending webhook");
        }
        setWebhookSending(false);
      })
      .catch((e) => {
        console.error(e);
        setWebhookSending(false);
      });
  }, [selectedWebhook, alertId]);

  if (!alert) {
    return null;
  }

  let severityColor;
  const severityLabel = alertSeverityLabels[alert.severity];
  switch (alert.severity) {
    case AlertSeverity.High:
      severityColor = "error.opacity50";
      break;
    default:
      severityColor = "light.opacity50";
      break;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      SlideProps={{
        onExited() {
          navigate(location.pathname.replace(`/${alertId}`, ""));
          setOpen(false);
        },
      }}
      onClose={() => {
        navigate(location.pathname.replace(`/${alertId}`, ""));
        setOpen(false);
      }}
      title={alert.title}
    >
      <Grid container spacing={[2, 3]}>
        <Grid item xs={6} md={4}>
          <Typography variant="caption">Date</Typography>
          <Typography>{formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="caption">Severity</Typography>
          <Typography>
            <TagChip label={severityLabel} sx={{ bgcolor: severityColor }} />
          </Typography>
        </Grid>
        {alert.chain && (
          <Grid item xs={6} md={4}>
            <Typography variant="caption">Chains</Typography>
            <Typography>
              <CryptoIcon icon={alert.chain?.toLowerCase()} sx={{ pr: 0.5 }} />
              {alert.chain}
            </Typography>
          </Grid>
        )}
        {alert.assets && (
          <Grid item xs={6} md={4}>
            <Typography variant="caption">Asset</Typography>
            {alert.assets?.map((asset) => (
              <Typography key={asset}>
                <CryptoIcon icon={wrappedSymbolToIconSymbol(asset)} sx={{ pr: 0.5 }} />
                {asset}
              </Typography>
            ))}
          </Grid>
        )}
        {alert.ccarClient && (
          <Grid item xs={6} md={4}>
            <Typography variant="caption">Protocol</Typography>
            <Typography>
              <CryptoIcon
                icon={mapProtocolToData[alert.ccarClient as CcarClient]?.icon || alert.ccarClient}
                sx={{ pr: 0.5 }}
              />
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="caption">Description</Typography>
          <Typography>{alert.description}</Typography>
        </Grid>
        {alert.jiraTicketUrl && (
          <Grid item xs={12}>
            <Link to={alert.jiraTicketUrl} target="_blank" onClick={(e) => e.stopPropagation()}>
              <Box display="flex" alignItems="center">
                <img style={{ display: "block", marginRight: 4 }} src={JiraLogo} alt="header" height={24} />
                <span>{alert.jiraTicketUrl.split("/").pop()}</span>
              </Box>
            </Link>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          mt={4}
          sx={{
            borderTop: "1px solid",
            borderColor: "almostWhite.opacity50",
            paddingTop: 4,
          }}
        >
          <Typography color="almostWhite.main" variant="h6">
            Send webhook
          </Typography>
          <CustomReactSelect
            disabled={loadingWebhooks}
            controlShouldRenderValue
            label=""
            placeholder="Select webhook"
            options={
              webhooks?.map((k) => ({
                value: k.name,
                label: k.name,
              })) ?? []
            }
            onChange={(e) => {
              setSelectedWebhook(e ? e.value : e);
            }}
            sx={{ marginTop: 2 }}
          />
          <Button
            color="primary"
            sx={{ marginTop: 2 }}
            size="small"
            onClick={handleSendWebhook}
            disabled={loadingWebhooks || selectedWebhook === null || webhookSending}
          >
            {webhookSending && <CircularProgress color="inherit" size="24px" sx={{ marginRight: 2 }} />}
            {webhookSending ? "Sending ..." : "Send"}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};
