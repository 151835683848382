import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useAvsDataQuery } from "../../../generated";

export const AvsCards = () => {
  const { data } = useAvsDataQuery();

  const cards: ValueCardProps[] = [
    {
      title: "Number of AVSs",
      value: data?.avsData?.length,
      icon: "pie-slice",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
