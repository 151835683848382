import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams, useLocation } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useNodeOperatorsQuery } from "../../generated";

const NodeOperatorDropdownHeader = () => {
  const { clientName, nodeOperatorId } = useParams();
  const location = useLocation();
  const { data, loading } = useNodeOperatorsQuery();

  const dropdownOptions = [...(data?.nodeOperators || [])]
    .sort((a, b) => b.tvrEth - a.tvrEth)
    .map(({ id, name }) => ({
      value: id,
      label: name,
      link: location.pathname.replace(nodeOperatorId!, id),
    }));

  const selectedNodeOperator = dropdownOptions.find((opt) => opt.value === nodeOperatorId);

  const breadcrumbsLinks = [
    {
      title: "Node Operators",
      href: RoutePath.Risk.NodeOperators.replace(RouteParams.ClientName, clientName!),
    },
  ];

  return (
    <PageHeader
      backLink={RoutePath.Risk.NodeOperators.replace(RouteParams.ClientName, clientName!)}
      pageTitle={
        <Box sx={{ display: "inline-flex", alignItems: "center", gap: 2 }} data-testid="page-title">
          <CustomReactSelect
            options={dropdownOptions}
            value={selectedNodeOperator}
            isSearchable={false}
            variant="h3"
            isLoading={loading}
          />
        </Box>
      }
      breadcrumbTitle={selectedNodeOperator?.label}
      breadcrumbsLinks={breadcrumbsLinks}
      containerStyle={{ mt: 0, mb: [2, 5.5] }}
      showBackButton
    />
  );
};

export default NodeOperatorDropdownHeader;
