import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { dataFetchersURL } from "src/utils/data-fetchers-url";

export interface AlertWebhook {
  name: string;
  url: string;
  alertIds?: string[];
  ccarClients?: string[];
  allClientsEnabled: boolean;
  allAlertsEnabled: boolean;
}

export const useAlertWebhooks = (): {
  data: AlertWebhook[] | undefined;
  alertIds: string[];
  loading: boolean;
  refresh?: () => Promise<void>;
} => {
  const [webhooks, setWebhooks] = useState<AlertWebhook[]>();
  const [alertIds, setAlertIds] = useState<string[]>([]);
  const [loadingWebhooks, setLoadingWebhooks] = useState(true);
  const [loadingAlertIds, setLoadingAlertIds] = useState(true);

  const getWebhooks = async () => {
    const res = await axios.get<AlertWebhook[]>(`${dataFetchersURL()}data/webhooks`, {
      withCredentials: true,
    });
    return res.data;
  };

  const getAlertIds = async () => {
    const res = await axios.get<string[]>(`${dataFetchersURL()}data/webhooks/alert-ids`, {
      withCredentials: true,
    });
    return res.data;
  };

  const fetchWebhooks = useCallback(async () => {
    setLoadingWebhooks(true);
    try {
      const data = await getWebhooks();
      setWebhooks(data);
    } catch (e) {
      setWebhooks([]);
    }
    setLoadingWebhooks(false);
  }, []);

  const fetchAlertIds = useCallback(async () => {
    setLoadingAlertIds(true);
    try {
      const data = await getAlertIds();
      setAlertIds(data);
    } catch (e) {
      setAlertIds([]);
    }
    setLoadingAlertIds(false);
  }, []);

  useEffect(() => {
    void fetchWebhooks();
    void fetchAlertIds();
  }, [fetchWebhooks, fetchAlertIds]);

  return {
    data: webhooks,
    alertIds,
    loading: loadingWebhooks || loadingAlertIds,
    refresh: fetchWebhooks,
  };
};
