import { useState } from "react";
import {
  Box,
  ChaosTable,
  Chip,
  ControlledFilters,
  CryptoIcon,
  makePickerFilter,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatDateAndTime } from "@frontend/ui/utils/formatters";
import dayjs from "dayjs";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { Link, Route, Routes } from "react-router-dom";
import JiraLogo from "src/assets/img/Jira.svg";
import { RouteParams } from "src/config/route-params";
import { Stack } from "@mui/material";
import { AlertDrawer } from "./alert-drawer";
import { alertSeverityLabels, alertSeverityStatus, alertTypeLabels } from "../../../../utils/consts";
import { usePageTitle } from "../../../../hooks";
import { TitleCell } from "../../../ccar-lending-page/tabs/alerts/components";
import { CcarClient, mapProtocolToData } from "./utils";
import { AlertFilteringTypes, useAlertsData } from "./use-alerts-data";
import { InternalAlert } from "../../generated";

const alertsHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Severity",
    field: "severity",
  },
  {
    renderType: "TEXT",
    text: "Date",
    field: "created",
  },
  {
    renderType: "TEXT",
    text: "Protocol",
    field: "ccarClient",
  },
  {
    renderType: "TEXT",
    text: "Protocol Version",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Risk Type",
    width: "150px",
    field: "type",
  },
  {
    renderType: "TEXT",
    text: "Name",
    field: "title",
  },
  {
    renderType: "TEXT",
    text: "Chain",
    field: "chain",
  },
  {
    renderType: "TEXT",
    text: "Market",
    field: "assetsSymbol",
  },
  {
    renderType: "TEXT",
    text: "Jira Task",
    nonSortable: true,
  },
];

const mapAlerts = (alert: InternalAlert): RenderData[] => {
  const formattedCreatedDate = `${formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}`;
  const severityStatus = alertSeverityStatus[alert.severity];
  const severityLabel = alertSeverityLabels[alert.severity];

  return [
    {
      text: severityLabel,
      renderType: "LABELS",
      status: severityStatus,
    },
    {
      renderType: "TEXT",
      text: formattedCreatedDate,
      value: alert.createdDate,
    },
    {
      renderType: "TEXT",
      text: mapProtocolToData[alert.ccarClient as CcarClient]?.name || alert.ccarClient,
      cryptoIcon: mapProtocolToData[alert.ccarClient as CcarClient]?.icon || alert.ccarClient,
    },
    {
      renderType: "TEXT",
      text: mapProtocolToData[alert.ccarClient as CcarClient]?.version,
    },
    {
      renderType: "TEXT",
      text: alert.type ? alertTypeLabels[alert.type] : "",
    },
    {
      renderType: "CUSTOM",
      text: alert.title,
      component: <TitleCell title={alert.title} isOpen={alert.open} />,
    },
    {
      renderType: "TEXT",
      text: alert.chain as string,
      cryptoIcon: alert.chain?.toLowerCase(),
    },
    {
      renderType: "TEXT",
      cryptoIcon: alert.assets?.length ? wrappedSymbolToIconSymbol(alert.assets[0]) : "",
      text: alert.assets ? alert.assets[0] : "",
    },
    {
      renderType: "CUSTOM",
      text: alert.jiraTicketUrl ?? "",
      component: alert.jiraTicketUrl ? (
        <Link to={alert.jiraTicketUrl} target="_blank" onClick={(e) => e.stopPropagation()}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <img style={{ display: "block", marginRight: 4 }} src={JiraLogo} alt="header" height={24} />
            <span>{alert.jiraTicketUrl.split("/").pop()}</span>
          </Box>
        </Link>
      ) : undefined,
    },
  ];
};

const ALERTS_PER_PAGE = 10;

const filters = [
  {
    ...makePickerFilter({
      title: "Protocols",
      fieldName: "ccarClient",
      options: Object.entries(mapProtocolToData).map(([protocol, data]) => ({
        name: data.version ? `${data.name} ${data.version}` : data.name,
        cryptoIcon: data.icon,
        value: protocol,
      })),
    }),
    fieldIndex: 0,
  },
  {
    ...makePickerFilter({
      title: "Groups",
      fieldName: "group",
      options: Object.entries(alertTypeLabels).map(([type, label]) => ({
        name: label,
        value: type,
      })),
    }),
    fieldIndex: 1,
  },
];

export const AlertsTab = () => {
  usePageTitle("Alerts");

  const [isActionable, setIsActionable] = useState<AlertFilteringTypes>("All");
  const [resetPagination, setResetPagination] = useState<string>();
  const { loading, alerts, onFiltersChange, onSortChange, onPageChange, onSearch, filter, setActionable } =
    useAlertsData({
      setResetPagination,
      pageSize: ALERTS_PER_PAGE,
    });

  const handleActionableChange = (value: AlertFilteringTypes) => {
    setIsActionable(value);
    setActionable(value);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={[2, 3]}>
      <ChaosTable
        pageSize={ALERTS_PER_PAGE}
        isFullHeight
        isLoading={loading}
        isSettingsHidden
        title=" "
        titleSuffixComponent={
          <ToggleButtonGroup
            fullWidth
            value={isActionable}
            exclusive
            onChange={(_, v) => {
              handleActionableChange(v as AlertFilteringTypes);
            }}
            size="medium"
          >
            <ToggleButton key="all" value="All">
              All Alerts
            </ToggleButton>
            <ToggleButton key="actionable" value="Actionable">
              Actionable Alerts
            </ToggleButton>
            <ToggleButton key="non-actionable" value="Non-Actionable">
              Informative Alerts
            </ToggleButton>
          </ToggleButtonGroup>
        }
        headers={alertsHeaders}
        data={alerts.map(mapAlerts)}
        isFilterHidden
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        resetPagination={resetPagination}
        serchbarPlaceholder="Search Alert"
        onSearch={onSearch}
        showSearch
        emptyState={{
          icon: "warning",
          title: "No Alerts Found",
        }}
        rowHref={(rowId) => {
          const row = alerts[rowId];
          if (!row) {
            return "";
          }
          const { id } = row;
          if (!id) {
            return "";
          }
          return `${String(id)}`;
        }}
        showRowChevron
        headerSuffixComponent={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: { xs: 1, lg: 0 },
              gap: 2,
              ml: { xs: 0, lg: "auto" },
              flexWrap: "wrap",
            }}
          >
            {filter?.ccarClients && (
              <Stack direction="row" spacing={1} display="flex" alignItems="center">
                <Typography>Protocols:</Typography>
                {filter?.ccarClients.map((ccarClient) => {
                  const protocolData = mapProtocolToData[ccarClient as CcarClient];
                  return (
                    <Chip
                      key={ccarClient}
                      icon={<CryptoIcon icon={protocolData.icon} sx={{ img: { width: 16, height: 16 } }} />}
                      label={protocolData.version ? `${protocolData.name} ${protocolData.version}` : protocolData.name}
                    />
                  );
                })}
              </Stack>
            )}
            {filter?.groups && (
              <Stack direction="row" spacing={1} display="flex" alignItems="center">
                <Typography>Groups:</Typography>
                {filter?.groups.map((group) => <Chip key={group} label={alertTypeLabels[group]} />)}
              </Stack>
            )}
          </Box>
        }
        customFilter={<ControlledFilters filters={filters} onChange={onFiltersChange} />}
      />
      <Routes>
        <Route path={`/${RouteParams.Alert}`} element={<AlertDrawer />} />
      </Routes>
    </Box>
  );
};
