import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useParams } from "react-router-dom";
import { useNodeOperatorsQuery } from "../../../generated";

const NodeOperatorCards = () => {
  const { nodeOperatorId } = useParams();
  const { data } = useNodeOperatorsQuery();

  const selectedOperator = data?.nodeOperators?.find((d) => d.id === nodeOperatorId);

  const cards: ValueCardProps[] = [
    {
      title: "TVR",
      value: selectedOperator?.tvrEth,
      icon: "coins-stack-icon",
      cryptoCurrency: "ETH",
    },
    {
      title: "Number of AVSs",
      value: selectedOperator?.avs?.length,
      icon: "users",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default NodeOperatorCards;
