import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { TabsNavigation } from "src/components/layouts";
import { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { Chain } from "src/pages/ccar-lending-page/generated";

const ChainDropdownHeader = () => {
  const { clientName, chain } = useParams<{ clientName: string; chain: Chain }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { chains, chainsTab, isMultichain, hasEMode } = useClientConfig();

  useEffect(() => {
    if (!chains.includes(chain!)) {
      navigate(RoutePath.Risk.Chains.replace(RouteParams.ClientName, clientName!));
    }
  }, [chain, chains, navigate, clientName]);

  const dropdownOptions = chains.map((c) => ({
    value: c,
    label: c,
    cryptoIcon: wrappedSymbolToIconSymbol(c),
    link: location.pathname.replace(chain!, c),
  }));

  const replaceParams = (path: string) =>
    path.replace(RouteParams.ClientName, clientName!).replace(RouteParams.Chain, chain!);

  const chainsPageTabs: NavigationTabProps[] = [
    { label: "Listed Assets", path: replaceParams(RoutePath.Risk.ChainDetailsListedAssets) },
    ...(hasEMode ? [{ label: "E-Mode", path: replaceParams(RoutePath.Risk.ChainDetailsEMode) }] : []),
    ...(chainsTab?.assetRecommendations
      ? [{ label: "Recommendations", path: replaceParams(RoutePath.Risk.ChainDetailsRecommendations) }]
      : []),
  ];

  const pageTitle = isMultichain && (
    <Box sx={{ display: "inline-block" }} data-testid="page-title">
      <CustomReactSelect
        options={dropdownOptions}
        value={dropdownOptions.find((option) => option.value === chain!)}
        isSearchable={false}
        variant="h3"
      />
    </Box>
  );

  const suffixComponent = chainsPageTabs.length > 1 ? <TabsNavigation variant="sub-nav" tabs={chainsPageTabs} /> : null;

  if (!pageTitle && !suffixComponent) {
    return null;
  }

  return (
    <PageHeader
      backLink={RoutePath.Risk.Chains.replace(RouteParams.ClientName, clientName!)}
      pageTitle={pageTitle}
      suffixComponent={suffixComponent}
      containerStyle={{ mt: 0, mb: [2, 5.5] }}
      showBackButton={isMultichain}
    />
  );
};

export default ChainDropdownHeader;
