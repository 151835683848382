import { Box } from "@frontend/ui";
import { WithdrawalCards, EventsTable, WithdrawalsCharts } from "./components";
import { EventStrategy, EventTypeFilter } from "../../generated";

const Overview = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <WithdrawalCards />
    <WithdrawalsCharts />
    <EventsTable
      title="Withdrawal Queue"
      eventType={EventTypeFilter.WithdrawalRequest}
      strategy={EventStrategy.EethEigenlayer}
      notProcessedRequests
      hideStrategyColumn
    />
    <EventsTable title="Withdrawal Events" eventType={EventTypeFilter.WithdrawalProcessed} />
  </Box>
);

export default Overview;
