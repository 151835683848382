export const RouteParams = {
  ClientName: ":clientName",
  DashboardId: ":dashboardId",
  Address: ":address",
  SubAccount: ":subAccount",
  Alert: ":alertId",
  // TODO: remove once we migrate to use marketId instead of market (canonical name)
  Market: ":market",
  MarketId: ":marketId",
  Asset: ":asset",
  MarketAsset: ":marketAsset",
  Chain: ":chain",
  PoolId: ":poolId",
  SimulationResultId: ":simulationResultId",
  PythonSimulationResultId: ":pythonSimulationResultId",
  PermutationId: ":permutationId",
  ExperimentType: ":experimentType",
  EModeId: ":eModeId",
  feedId: ":feedId",
  PositionId: ":positionId",
  CreditManagerId: ":creditManagerId",
  CreditAccountId: ":creditAccountId",
  AvsId: ":avsId",
  NodeOperatorId: ":nodeOperatorId",
};
