import { Box } from "@frontend/ui";
import { NodeOperatorsCards, NodeOperatorsTable, NodeOperatorsCharts } from "./components";

const NodeOperators = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <NodeOperatorsCards />
    <NodeOperatorsTable />
    <NodeOperatorsCharts />
  </Box>
);

export default NodeOperators;
