import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useOverviewQuery } from "../../generated";

export const OverviewCards = () => {
  const { data: overviewData } = useOverviewQuery();
  const { tvl, totalBorrowed, totalSupply, totalValueOnCreditAccounts } = overviewData?.overviewLeverage || {};

  const cards: ValueCardProps[] = [
    {
      title: "TVL",
      value: tvl,
      icon: "pie-slice",
      currency: "USD",
      deltaTooltip: "Change from the beginning of day",
    },
    {
      title: "Total Borrowed",
      value: totalBorrowed,
      currency: "USD",
      icon: "users",
    },
    {
      title: "Total Supply",
      value: totalSupply,
      icon: "clock",
    },
    {
      title: "Total Value on Credit Accounts",
      value: totalValueOnCreditAccounts,
      icon: "coin-dollar",
      currency: "USD",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={4} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
