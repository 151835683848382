import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import Markets from "../markets";
import { RiskTabsLayout } from "../../../components/risk-tabs-layout";
import {
  replaceHomePath,
  renderMarketPageLayout,
  renderAssetPageLayout,
  renderEModePageLayout,
  DefaultMarketPageTabRedirect,
} from "./layout";
import {
  MarketOverview,
  MarketListedAssets,
  EModeList,
  MarketAmm,
} from "../tabs";
import {
  AssetDetails,
  AssetPools,
  EModeDetails,
} from "../pages";
import { MarketsProvider, MarketListedAssetsProvider, MarketAssetsRecommendationsProvider } from "../context";

const MarketsRoutes = () => {
  const { clientName } = useParams();
  const { isMultiMarket, chains } = useClientConfig();

  return (
    <MarketsProvider>
      <MarketListedAssetsProvider>
        <MarketAssetsRecommendationsProvider>
          <Routes>
            <Route
              index
              element={
                isMultiMarket ? (
                  <RiskTabsLayout>
                    <Markets />
                  </RiskTabsLayout>
                ) : (
                  <Navigate
                    to={RoutePath.Risk.MarketDetailsListedAssets.replace(RouteParams.ClientName, clientName!).replace(
                      RouteParams.MarketId,
                      chains[0],
                    )}
                    replace
                  />
                )
              }
            />
            <Route path={replaceHomePath(RoutePath.Risk.MarketDetails)} element={<DefaultMarketPageTabRedirect />} />

            <Route
              path={replaceHomePath(RoutePath.Risk.MarketDetailsOverview)}
              element={renderMarketPageLayout(<MarketOverview />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketDetailsListedAssets)}
              element={renderMarketPageLayout(<MarketListedAssets />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketAssetPools)}
              element={renderAssetPageLayout(<AssetPools />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketAssetOverview)}
              element={renderAssetPageLayout(<AssetDetails />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketEmodeDetails)}
              element={renderEModePageLayout(<EModeDetails />)}
            />
            <Route
              path={replaceHomePath(RoutePath.Risk.MarketEModes)}
              element={renderMarketPageLayout(<EModeList />)}
            />
            <Route path={replaceHomePath(RoutePath.Risk.MarketAmm)} element={renderMarketPageLayout(<MarketAmm />)} />
          </Routes>
        </MarketAssetsRecommendationsProvider>
      </MarketListedAssetsProvider>
    </MarketsProvider>
  );
};
export default MarketsRoutes;
