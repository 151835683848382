import { Box, CompositionOverTimeChart, CryptoIcon, CustomSwitch, Grid, Paper, ValueOverTimeChart } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { ComponentProps, useState } from "react";
import { useParams } from "react-router-dom";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import TimeSpanPicker, {
  defaultTimeSpan,
} from "src/pages/ccar-lending-page/components/time-span-picker/time-span-picker";
import { Chain, TimeSpan, useHistoryStatsQuery } from "src/pages/ccar-lending-page/generated";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useMarketsContext } from "../../context/markets-context";
import { mapHistoryStatsPointsToSeriesData, mapSeriesData } from "./utils";

type Props = {
  chain?: Chain;
  tokenSymbol?: string;
  marketId?: string;
  market?: string;
  showCollateralChart?: boolean;
  hideTotalBorrowChart?: boolean;
  hideTotalSupplyChart?: boolean;
  groupBy?: "symbol" | "marketId";
  timeSpan?: TimeSpan;
  showTimeSpanPicker?: boolean;
};

const WrappedChart = (props: ComponentProps<typeof CompositionOverTimeChart>) => (
  <Paper variant="widget" sx={{ flex: 1, display: "flex" }}>
    <Box flex={1} width={0}>
      <CompositionOverTimeChart {...props} />
    </Box>
  </Paper>
);

const WrappedValueOverTimeChart = (props: ComponentProps<typeof ValueOverTimeChart>) => (
  <Paper variant="widget" sx={{ flex: 1, display: "flex" }}>
    <Box flex={1} width={0}>
      <ValueOverTimeChart {...props} currency="" isPercent yAxisMax yAxisMin />
    </Box>
  </Paper>
);

const MarketHistoryCharts = ({
  chain,
  tokenSymbol,
  marketId,
  market,
  showCollateralChart,
  hideTotalBorrowChart,
  hideTotalSupplyChart,
  timeSpan: timeSpanProp,
  groupBy = "symbol",
  showTimeSpanPicker = false,
}: Props) => {
  const { hasCollateralLabel, isMultiMarket, stableCoin, timeSpanOptions } = useClientConfig();
  const { chain: chainParam } = useParams<{ chain: Chain }>();
  const borrowSupplyInTokenEnabled = useFeatureFlag("borrow_supply_in_token");
  const [isValuesInUSD, setIsValuesInUSD] = useState(true);
  const isProtocolStablecoin = tokenSymbol && tokenSymbol.toLowerCase() === stableCoin?.asset.toLowerCase();

  const hasLongHistory = useFeatureFlag("long_history");
  const showApyHistory =
    useFeatureFlag("apy_history") && (!isProtocolStablecoin || stableCoin?.riskTab?.showApyHistory);
  const showDistributionHistory = useFeatureFlag("asset_distribution_history");

  const [selectedTimeSpan, setSelectedTimeSpan] = useState<TimeSpan>(defaultTimeSpan);
  const timeSpan = hasLongHistory && showTimeSpanPicker ? selectedTimeSpan : undefined;
  const componentOrPropsTimeSpan = timeSpan || timeSpanProp;

  const { markets } = useMarketsContext();
  const marketObj = markets.find((m) => m.id === marketId);
  const { borrowableAssets, collateralableAssets } = marketObj || {};

  const { loading, data } = useHistoryStatsQuery({
    variables: {
      chain: chain || chainParam!,
      tokenSymbol: tokenSymbol || null,
      marketId: marketId || null,
      market: market || null,
      withMarkets: groupBy === "marketId",
      timeSpan: timeSpan || timeSpanProp || null,
      includeAssetsBreakDown: !!marketId || !isMultiMarket,
    },
  });

  const assetsSeriesData = mapHistoryStatsPointsToSeriesData(
    data?.chainHistoryStats.data || [],
    isValuesInUSD,
    marketId,
    chain || chainParam!,
  );

  const supplyApyEmpty = !Object.values(assetsSeriesData)
    .flatMap((s) => s.supplyApy)
    .some((s) => s[1] > 0);
  const borrowApyEmpty = !Object.values(assetsSeriesData)
    .flatMap((s) => s.borrowApy)
    .some((s) => s[1] > 0);

  return (
    <Grid container spacing={2}>
      {(timeSpan || (tokenSymbol && borrowSupplyInTokenEnabled)) && (
        <Grid item xs={12} justifySelf="flex-end">
          <Box display="flex" width="100%" justifyContent="space-between">
            <Box>
              {timeSpan && (
                <TimeSpanPicker
                  selectedTimeSpan={timeSpan}
                  onSelectTimeSpan={setSelectedTimeSpan}
                  timeSpanOptions={isProtocolStablecoin ? stableCoin?.timeSpanOptions : timeSpanOptions}
                />
              )}
            </Box>
            {tokenSymbol && borrowSupplyInTokenEnabled && (
              <CustomSwitch
                onChange={() => setIsValuesInUSD((prev) => !prev)}
                checked={isValuesInUSD}
                checkedIcon="dollar"
                uncheckedIcon={<CryptoIcon icon={wrappedSymbolToIconSymbol(tokenSymbol)} />}
                labelsVariant="h4"
              />
            )}
          </Box>
        </Grid>
      )}
      {!hideTotalSupplyChart && (
        <Grid item xs={12} lg={hideTotalBorrowChart ? 12 : 6}>
          <WrappedChart
            title={`Total ${hasCollateralLabel ? "Collateral" : "Supply"}`}
            series={mapSeriesData(assetsSeriesData, "totalSupplyUsd", (symbol) => {
              const filterAssets = showCollateralChart ? borrowableAssets : undefined;
              if (!filterAssets) return true;
              return filterAssets.includes(symbol);
            })}
            isLoading={loading}
            currency={isValuesInUSD ? "USD" : ""}
            timeSpan={componentOrPropsTimeSpan}
          />
        </Grid>
      )}
      {!hideTotalBorrowChart && (
        <Grid item xs={12} lg={hideTotalSupplyChart ? 12 : 6}>
          <WrappedChart
            title="Total Borrow"
            series={mapSeriesData(assetsSeriesData, "totalBorrowUsd")}
            isLoading={loading}
            currency={isValuesInUSD ? "USD" : ""}
            timeSpan={componentOrPropsTimeSpan}
          />
        </Grid>
      )}
      {showCollateralChart && (
        <Grid item xs={12}>
          <WrappedChart
            title="Total Collateral"
            series={mapSeriesData(assetsSeriesData, "totalSupplyUsd", (symbol) =>
              !collateralableAssets ? true : collateralableAssets.includes(symbol),
            )}
            isLoading={loading}
            currency={isValuesInUSD ? "USD" : ""}
            showLegend={!tokenSymbol}
            timeSpan={componentOrPropsTimeSpan}
          />
        </Grid>
      )}

      {showApyHistory &&
        (tokenSymbol ? (
          <Grid item xs={12}>
            <WrappedValueOverTimeChart
              title="Supply & Borrow APY"
              series={[
                ...mapSeriesData(assetsSeriesData, "supplyApy", undefined, "Supply APY", true),
                ...mapSeriesData(assetsSeriesData, "borrowApy", undefined, "Borrow APY", true),
              ]}
              isLoading={loading}
              timeSpan={componentOrPropsTimeSpan}
              connectNulls
              emptyState={supplyApyEmpty && borrowApyEmpty}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} lg={6}>
              <WrappedValueOverTimeChart
                title="Supply APY"
                series={mapSeriesData(assetsSeriesData, "supplyApy")}
                isLoading={loading}
                timeSpan={componentOrPropsTimeSpan}
                emptyState={supplyApyEmpty}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <WrappedValueOverTimeChart
                title="Borrow APY"
                series={mapSeriesData(assetsSeriesData, "borrowApy")}
                isLoading={loading}
                timeSpan={componentOrPropsTimeSpan}
                emptyState={borrowApyEmpty}
              />
            </Grid>
          </>
        ))}

      {showDistributionHistory && tokenSymbol && (
        <>
          <Grid item xs={6}>
            <WrappedChart
              title="Borrow Against Distribution"
              series={[...mapSeriesData(assetsSeriesData, "borrowAgainstDistribution")]}
              isLoading={loading}
              timeSpan={componentOrPropsTimeSpan}
              currency="USD"
            />
          </Grid>
          <Grid item xs={6}>
            <WrappedChart
              title="Collateral Against Distribution"
              series={[...mapSeriesData(assetsSeriesData, "collateralDistribution")]}
              isLoading={loading}
              timeSpan={componentOrPropsTimeSpan}
              currency="USD"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MarketHistoryCharts;
