import { Box, CustomReactSelect, Typography } from "@frontend/ui";
import { wrappedSymbolToIconSymbol } from "@frontend/ui/utils/icon-helper";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import groupBy from "lodash/groupBy";
import TabsNavigation, { NavigationTabProps } from "src/components/layouts/tabs-navigation";
import { Breadcrumb, PageHeader } from "@frontend/ui/page-header";
import { useFeatureFlag } from "src/utils/feature-flags";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { getTabByPath } from "./get-tab-route-by-path";
import { useAssetsDropdownOptionsByTab } from "../../use-assets-dropdown-options";
import { useMarketListedAssetsContext } from "../../../context/market-listed-assets-context";
import { useMarketsContext } from "../../../context/markets-context";
import { getAssetRouteByTabPath } from "./get-asset-tab-route-by-path";
import { useMarketAssetsRecommendationsContext } from "../../../context";

export type MarketAssetDropdownHeaderProps = {
  currentBreadcrumbTitle?: string;
  getAdditionalBreadcrumbs?: (marketId: string, asset: string) => Breadcrumb[];
};

const MarketAssetDropdownHeader = ({
  currentBreadcrumbTitle,
  getAdditionalBreadcrumbs,
}: MarketAssetDropdownHeaderProps) => {
  const { clientName, marketId, asset } = useParams();
  const location = useLocation();
  const { listedAssets } = useMarketListedAssetsContext();
  const { assetsRecommendations, isUnderReview } = useMarketAssetsRecommendationsContext();
  const { isLoading: isLoadingAssets, assets } = useAssetsDropdownOptionsByTab();
  const { markets, isLoading: isLoadingMarkets } = useMarketsContext();
  const { markets: marketsConfig, stableCoin, showChainMarketsBreakdown, chains, marketType } = useClientConfig();

  const isLoading = isLoadingAssets || isLoadingMarkets;
  const marketTab = getTabByPath(location.pathname, isUnderReview);

  const marketsByChain = groupBy(markets, "chain");
  const selectedMarketData = markets.find((m) => m.id === marketId!);

  const marketsToSelect =
    showChainMarketsBreakdown && selectedMarketData ? marketsByChain[selectedMarketData.chain] : markets;

  const marketsDropdownOptions = marketsToSelect.map(({ name, id }) => ({
    value: id,
    label: name,
    cryptoIcon: marketType !== "GeneralMarket" && getIconSrc(name) ? name.toLowerCase() : undefined,
    link: marketTab.path.replace(RouteParams.MarketId, id).replace(RouteParams.ClientName, clientName!),
  }));

  const chainDropdownOptions = chains.map((chain) => ({
    value: chain,
    label: chain,
    cryptoIcon: chain,
    link: marketTab.path
      .replace(RouteParams.MarketId, marketsByChain[chain]?.[0]?.id || "")
      .replace(RouteParams.ClientName, clientName!),
  }));
  const selectedChain = chainDropdownOptions.find((option) => option.value === selectedMarketData?.chain);

  const defaultAssetOption = [asset!];
  const marketsAssetsDropdownOptions = (assets || defaultAssetOption).map((s) => {
    const stableCoinPath =
      stableCoin?.riskTab?.enabled &&
      stableCoin?.asset === s &&
      RoutePath.Risk.StablecoinOverview(stableCoin.asset).replace(RouteParams.ClientName, clientName!);

    const path =
      (stableCoinPath ||
        marketTab.assetPath
          ?.replace(RouteParams.MarketId, marketId!)
          ?.replace(RouteParams.ClientName, clientName!)
          ?.replace(RouteParams.Asset, s)) ??
      getAssetRouteByTabPath(location.pathname, asset || "", s); // replace last occurrence

    return {
      value: s,
      label: s,
      cryptoIcon: wrappedSymbolToIconSymbol(s),
      link: path,
    };
  });

  const replaceParams = (path: string) =>
    path
      .replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.MarketId, marketId!)
      .replace(RouteParams.Asset, asset!);

  const isListedAsset = listedAssets?.some((a) => a.asset === asset!);
  const selectedAsset = listedAssets?.find((a) => a.asset === asset!);
  const hasRecommendations = assetsRecommendations?.some((r) => r.assetName === asset!);

  const poolsEnabled = useFeatureFlag("asset-pools");

  const assetPageTabs: NavigationTabProps[] = [
    ...(isListedAsset ? [{ label: "Risk", path: replaceParams(RoutePath.Risk.MarketAssetOverview) }] : []),
    ...(isListedAsset && poolsEnabled
      ? [{ label: "Pools", path: replaceParams(RoutePath.Risk.MarketAssetPools) }]
      : []),
    ...(marketsConfig?.assetRecommendations && hasRecommendations
      ? [{ label: "Recommendations", path: replaceParams(RoutePath.Risk.MarketAssetRecommendations(isUnderReview)) }]
      : []),
  ];

  const hasMarkets = markets && markets?.length > 1;
  const currentMarket = marketsDropdownOptions.find((option) => option.value === marketId!);

  const marketBreadcrumbsLinks =
    hasMarkets && currentMarket
      ? [
          {
            title: "Markets",
            href: RoutePath.Risk.Markets.replace(RouteParams.ClientName, clientName!),
          },
          {
            title: currentMarket.label,
            href: RoutePath.Risk.MarketDetails.replace(RouteParams.ClientName, clientName!).replace(
              RouteParams.MarketId,
              marketId!,
            ),
          },
        ]
      : [];

  const assetsTabPath = marketTab.path
    .replace(RouteParams.ClientName, clientName!)
    .replace(RouteParams.MarketId, marketId!);

  const additionalBreadcrumbs = getAdditionalBreadcrumbs?.(marketId!, asset!);

  const breadcrumbsLinks = [
    ...marketBreadcrumbsLinks,
    {
      title: marketTab.label,
      href: assetsTabPath,
    },
    ...(additionalBreadcrumbs || []),
  ];

  return (
    <PageHeader
      backLink={breadcrumbsLinks[breadcrumbsLinks.length - 1].href}
      pageTitle={
        <Box display="flex" gap={[1, 2]} alignItems="center" data-testid="page-title">
          {showChainMarketsBreakdown && selectedMarketData && (
            <>
              <CustomReactSelect
                options={chainDropdownOptions}
                value={selectedChain}
                isSearchable={false}
                variant="h3"
              />
              <Typography variant="h3" color="almostWhite.main">
                /
              </Typography>
            </>
          )}
          {hasMarkets && (
            <>
              <CustomReactSelect
                disabled={isLoading}
                options={marketsDropdownOptions}
                value={marketsDropdownOptions.find((option) => option.value === marketId!)}
                isSearchable={false}
                variant="h3"
              />
              <Typography variant="h3" color="almostWhite.main">
                /
              </Typography>
            </>
          )}
          <CustomReactSelect
            disabled={isLoading}
            options={marketsAssetsDropdownOptions}
            value={marketsAssetsDropdownOptions.find((option) => option.value === asset!)}
            isSearchable={false}
            variant="h3"
          />
          {!!selectedAsset?.isFrozen && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={1}
              px={1}
              bgcolor="error.main"
            >
              Frozen Asset
            </Box>
          )}
        </Box>
      }
      breadcrumbTitle={currentBreadcrumbTitle ?? asset}
      breadcrumbsLinks={breadcrumbsLinks}
      suffixComponent={assetPageTabs.length > 1 && <TabsNavigation variant="sub-nav" tabs={assetPageTabs} />}
      containerStyle={{ mt: 0, mb: [1, 2] }}
    />
  );
};

export default MarketAssetDropdownHeader;
