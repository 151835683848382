import { useState } from "react";
import { Box, CompositionOverTimeChart, Paper } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { Chain, TimeSpan, useTvlHistoryQuery } from "../generated";
import { TimeSpanPicker } from "../../ccar-lending-page/components/time-span-picker";

type Props = {
  chain?: Chain;
};

const TvlChart = ({ chain }: Props) => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Month);

  const { loading, data } = useTvlHistoryQuery({
    variables: {
      timeSpan,
      chain,
    },
  });

  const seriesByChain = data?.tvlLeverageHistory.reduce(
    (acc, h) => {
      const prevSeries = acc[h.chain];
      const prevData = prevSeries?.data || [];
      const newDataPoint = [h.timestamp, h.value] as [number, number];
      const newData = [...prevData, newDataPoint];

      return {
        ...acc,
        [h.chain]: {
          label: h.chain,
          data: newData,
        },
      };
    },
    {} as Record<Chain, NumberChartSeries>,
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box marginLeft="auto">
        <TimeSpanPicker selectedTimeSpan={timeSpan} onSelectTimeSpan={setTimeSpan} />
      </Box>
      <Paper variant="widget">
        <CompositionOverTimeChart
          title="TVL Over Time"
          series={Object.values(seriesByChain || {})}
          timeSpan={timeSpan}
          isLoading={loading}
          fillMissingPoints
          hideLegend={!!chain}
        />
      </Paper>
    </Box>
  );
};

export default TvlChart;
