import { useParams } from "react-router-dom";
import { ChaosTable, currencyCell, iconsCell, numberCell, textCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { useMemo } from "react";
import { useNodeOperatorsQuery } from "../../../generated";
import { RouteParams, RoutePath } from "../../../../../config/routes";

export const NodeOperatorsTable = () => {
  const { data, loading } = useNodeOperatorsQuery();
  const { clientName } = useParams();

  const headers: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Operator Name",
        width: "200px",
      },
      {
        renderType: "TEXT",
        text: "TVR",
        prefix: "ETH",
      },
      {
        renderType: "ICONS",
        text: "Number of AVSs",
      },
      {
        renderType: "ICONS",
        text: "AVSs",
      },
    ],
    [],
  );

  const tableData: RenderData[][] = (data?.nodeOperators || []).map((operator) => [
    textCell(operator.name),
    currencyCell(operator.tvrEth || 0, { cryptoCurrency: "ETH" }),
    numberCell(operator.avs.length),
    iconsCell(operator.avs.map((a) => a.name)),
  ]);

  const getRowHref = (rowId: number) => {
    const operator = data?.nodeOperators?.[rowId];

    if (!operator) {
      return "";
    }

    return RoutePath.Risk.NodeOperatorDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.NodeOperatorId,
      operator.id,
    );
  };

  return (
    <ChaosTable
      title="Node Operators"
      initialSortBy={1}
      isInitialSortDesc
      showRowChevron
      pageSize={15}
      headers={headers}
      data={tableData}
      isLoading={loading}
      rowHref={getRowHref}
      isFullHeight
    />
  );
};
