import { Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { useNodeOperatorsQuery } from "../../../generated";

export const NodeOperatorsCards = () => {
  const { data } = useNodeOperatorsQuery();

  const cards: ValueCardProps[] = [
    {
      title: "Number of Node Operators",
      value: data?.nodeOperators?.length,
      icon: "users",
    },
    {
      title: "TVR",
      value: data?.nodeOperators?.reduce((tvr, o) => tvr + o.tvrEth, 0),
      icon: "lock-icon",
      cryptoCurrency: "ETH",
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={6} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
