import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "../../../config/routes";
import { replaceHomePath } from "./utils";
import { AvsTab, OverviewTab, WithdrawalsTab, NodeOperatorsTab } from "../tabs";
import { renderAvsPageLayout, renderNodeOperatorPageLayout, renderTabsLayout } from "./layout/layout";
import { ChaosApolloProvider } from "../../../utils/graphql";
import { FeatureFlagsProvider } from "../../../utils/feature-flags";
import { AvsDetails, NodeOperatorsDetails } from "../pages";

const RiskMonitoringStakingRoutes = () => {
  const { clientName } = useParams();
  const defaultRoute = RoutePath.Risk.Overview.replace(RouteParams.ClientName, clientName!);

  return (
    <ChaosApolloProvider product="staking">
      <FeatureFlagsProvider>
        <Routes>
          <Route index element={<Navigate to={defaultRoute} replace />} />
          <Route path={replaceHomePath(RoutePath.Risk.Overview)} element={renderTabsLayout(<OverviewTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.Withdrawals)} element={renderTabsLayout(<WithdrawalsTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.Avs)} element={renderTabsLayout(<AvsTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.AvsDetails)} element={renderAvsPageLayout(<AvsDetails />)} />
          <Route
            path={replaceHomePath(RoutePath.Risk.NodeOperators)}
            element={renderTabsLayout(<NodeOperatorsTab />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.NodeOperatorDetails)}
            element={renderNodeOperatorPageLayout(<NodeOperatorsDetails />)}
          />
          <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
};

export default RiskMonitoringStakingRoutes;
