import { EChartsOption } from "echarts";
import { palette } from "../../../theme/palette";
import { StringChartProps } from "../../types";
import { formatEChartValue } from "../../utils";
import { stackedBarChartTooltipFormatter } from "./tooltipFormatter";

export const getStackedBarOptions = ({
  series,
  isPercent,
  currency,
  yAxisLabel,
  xAxisLabel,
  showXAxisLine = true,
  showLegend = true,
  xAxisFontSize,
  cryptoCurrency,
  tooltipShowTotal,
  xAxisData,
  formatXAxisLabel,
  tooltipHeaderFormatter,
}: StringChartProps): EChartsOption => {
  const hasStackGroups = series.some((s) => s.stack);

  return {
    tooltip: {
      trigger: "axis",
      formatter: stackedBarChartTooltipFormatter({
        headerFormatter: tooltipHeaderFormatter,
        valueFormatter: (v) =>
          formatEChartValue({ isPercent, currency, notation: "standard", cryptoCurrency })(Number(v)),
        currency,
        showTotal: tooltipShowTotal,
      }),
      ...(hasStackGroups && {
        axisPointer: {
          type: "shadow",
        },
      }),
      padding: 0,
      renderMode: "auto",
      verticalAlign: "middle",
    },
    legend: {
      show: showLegend,
      data: series.map((serie) => serie.label || ""),
      type: "scroll",
      padding: [0, 24, 0, 0],
    },
    grid: {
      bottom: xAxisLabel ? 30 : 5,
      top: showLegend ? 60 : 20,
      left: yAxisLabel ? 45 : 2,
      right: 15,
    },
    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      type: "category",
      name: xAxisLabel,
      nameLocation: "middle",
      nameGap: 50,
      axisLine: {
        show: showXAxisLine,
        lineStyle: {
          color: palette.red.main,
          type: [2, 5],
          width: 2,
        },
      },
      axisLabel: {
        align: "center",
        fontSize: xAxisFontSize || 12,
        formatter: formatXAxisLabel,
      },
      zlevel: 1,
      data: xAxisData,
    },
    yAxis: {
      type: "value",
      name: yAxisLabel,
      nameLocation: "middle",
      position: "left",
      nameGap: 60,
      axisLabel: {
        fontSize: 12,
        formatter: (v: number) => {
          if (Math.abs(v) >= 1) return formatEChartValue({ isPercent, currency, cryptoCurrency })(Number(v));
          return formatEChartValue({ isPercent, currency, notation: "standard", cryptoCurrency })(Number(v));
        },
      },
    },
    series: series.map((serie) => {
      const color = series.length === 1 ? palette.blue.main : serie.color;
      return {
        id: serie.id,
        name: serie.label,
        type: "bar",
        barWidth: !hasStackGroups ? "50%" : undefined,
        barMaxWidth: 24,
        barGap: !hasStackGroups ? 1 : undefined,
        stack: serie.stack || "total",
        color,
        data: serie.data,
      };
    }),
  };
};
