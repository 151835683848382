import { Box, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { PieChart } from "@frontend/ui/charts";
import { useAvsDataQuery } from "../../../../generated";

const TvrPieChart = () => {
  const { data, loading } = useAvsDataQuery();
  const chartData = (data?.avsData || []).map((c) => ({
    x: c.name,
    y: c.protocols.find((p) => p.protocol === "EtherFi")?.delegationEth || 0,
  }));

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">AVS TVR</Typography>
      {loading ? (
        <ShimmerLoader sx={{ height: 288 }} />
      ) : (
        <PieChart data={chartData} format="number" labelSuffix="ETH" size={288} showLegendIcon />
      )}
    </Box>
  );
};

export default TvrPieChart;
