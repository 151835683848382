import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams, useLocation } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useAvsDataQuery } from "../../generated";

const AvsDropdownHeader = () => {
  const { clientName, avsId } = useParams();
  const location = useLocation();
  const { data, loading } = useAvsDataQuery();

  const dropdownOptions = [...(data?.avsData || [])]
    .sort((a, b) => b.tvrEth - a.tvrEth)
    .map(({ id, name }) => ({
      value: id,
      label: name,
      cryptoIcon: name,
      link: location.pathname.replace(avsId!, id),
    }));

  const selectedAvs = dropdownOptions.find((opt) => opt.value === avsId);

  const breadcrumbsLinks = [
    {
      title: "AVS",
      href: RoutePath.Risk.Avs.replace(RouteParams.ClientName, clientName!),
    },
  ];

  return (
    <PageHeader
      backLink={RoutePath.Risk.Avs.replace(RouteParams.ClientName, clientName!)}
      pageTitle={
        <Box sx={{ display: "inline-flex", alignItems: "center", gap: 2 }} data-testid="page-title">
          <CustomReactSelect
            options={dropdownOptions}
            value={selectedAvs}
            isSearchable={false}
            variant="h3"
            isLoading={loading}
          />
        </Box>
      }
      breadcrumbTitle={selectedAvs?.label}
      breadcrumbsLinks={breadcrumbsLinks}
      containerStyle={{ mt: 0, mb: [2, 5.5] }}
      showBackButton
    />
  );
};

export default AvsDropdownHeader;
