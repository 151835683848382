import ReactECharts, { EChartsReactProps } from "echarts-for-react";
import type { EChartsOption } from "echarts";
import * as echarts from "echarts";
import { createRef, useEffect, useMemo } from "react";
import { Typography } from "../typography";
import { Box } from "../box";
import { theme } from "./theme";
import { useKeyPress } from "../utils/use-key-press";

echarts.registerTheme("main", theme);
type Props = Omit<EChartsReactProps, "option"> & {
  zoomable?: boolean;
  onClick?: (x: number, y: number) => void;
  emptyState?: boolean;
  emptyStateText?: string;
  disableWatermark?: boolean;
  option: EChartsOption;
};
const ECharts = ({ zoomable, onClick, option, emptyState, emptyStateText, disableWatermark, ...restProps }: Props) => {
  const chartRef = createRef<ReactECharts>();
  const zoomKeyPressed = useKeyPress(["ctrl", "Meta"], zoomable);
  const brushKeyPressed = useKeyPress(["Shift"], zoomable);

  const waterMarkOption = useMemo(() => {
    if (disableWatermark) return option;

    const graphics = Array.isArray(option.graphic) ? option.graphic : option.graphic ? [option.graphic] : [];

    return {
      ...option,
      graphic: [
        ...graphics,
        {
          type: "image",
          id: "logo",
          left: "center",
          top: "center",
          style: {
            image: "/chaos-logo.svg",
            width: 184,
            height: 42,
          },
        },
      ],
    };
  }, [option, disableWatermark]);

  const zoomOption = useMemo(() => {
    const { dataZoom } = waterMarkOption;
    if (!zoomable || typeof dataZoom !== "object" || Array.isArray(dataZoom)) return waterMarkOption;
    return { ...waterMarkOption, dataZoom: { ...dataZoom, zoomLock: !zoomKeyPressed } };
  }, [waterMarkOption, zoomKeyPressed, zoomable]);

  useEffect(() => {
    const echartInstance = chartRef?.current?.getEchartsInstance();

    if (echartInstance && zoomable) {
      echartInstance.dispatchAction({
        type: "takeGlobalCursor",
        key: "dataZoomSelect",
        dataZoomSelectActive: brushKeyPressed,
      });
    }
  }, [zoomable, brushKeyPressed, chartRef]);

  return emptyState ? (
    <Box display="flex" alignItems="center" justifyContent="center" height="80%">
      <Typography variant="h4" color="light.main">
        {emptyStateText || "No Data to Show"}
      </Typography>
    </Box>
  ) : (
    <ReactECharts
      ref={chartRef}
      {...restProps}
      onEvents={{
        click: (c: { data?: [number, number] }) => {
          if (onClick && Array.isArray(c.data) && c.data.length === 2) {
            onClick(c.data[0], c.data[1]);
          }
        },
      }}
      option={zoomOption}
      style={{ height: "100%", width: "100%" }}
      theme="main"
    />
  );
};

export default ECharts;
