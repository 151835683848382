import { Grid, Paper } from "@frontend/ui";
import TvrPieChart from "./tvr-pie-chart";

export const AvsCharts = () => (
  <Paper variant="card" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="widget">
          <TvrPieChart />
        </Paper>
      </Grid>
    </Grid>
  </Paper>
);
