import {
  Box,
  Button,
  Checkbox,
  CustomMultiReactSelect,
  Drawer,
  MultiOptionType,
  TextField,
  Typography,
} from "@frontend/ui";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { AlertWebhook, convertAlertIdToDisplayName, convertClientToDisplayName } from "./utils";
import { dataFetchersURL } from "../../../../utils/data-fetchers-url";
import { CcarClient } from "../alerts/utils";
import { useAlertWebhooks } from "../../hooks/useWebhooks";

interface WebhookFormDrawerProps {
  open: boolean;
  webhook: AlertWebhook | null;
  onClose: () => void;
}

interface AlertsWebhookForm extends Omit<AlertWebhook, "alertIds" | "ccarClients"> {
  alertIds: MultiOptionType[];
  ccarClients: MultiOptionType[];
}

export const WebhookFormDrawer = ({ open, onClose, webhook }: WebhookFormDrawerProps) => {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm<AlertsWebhookForm>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const allAlertsEnabled = useWatch({ control, name: "allAlertsEnabled" });
  const allClientsEnabled = useWatch({ control, name: "allClientsEnabled" });

  const [submitting, setSubmitting] = useState(false);
  const { alertIds, data } = useAlertWebhooks();

  useEffect(() => {
    if (webhook) {
      reset({
        ...webhook,
        alertIds: webhook.alertIds?.map((a) => ({
          value: a,
          label: convertAlertIdToDisplayName(a),
        })),
        ccarClients: webhook.ccarClients?.map((a) => ({
          value: a,
          label: convertClientToDisplayName(a),
        })),
      });
    } else {
      reset({});
    }
  }, [webhook, reset]);

  useEffect(() => {
    setSubmitting(false);
  }, [open]);

  const onSubmit = async () => {
    const values = getValues();

    setSubmitting(true);

    try {
      const res = await axios.post<AlertWebhook>(
        `${dataFetchersURL()}data/webhooks`,
        {
          name: values.name ?? webhook?.name,
          url: values.url ?? webhook?.url,
          allAlertsEnabled: !!allAlertsEnabled,
          allClientsEnabled: !!allClientsEnabled,
          alertIds: allAlertsEnabled ? [] : values.alertIds?.map((a: MultiOptionType) => a.value) ?? webhook?.alertIds,
          ccarClients: allClientsEnabled
            ? []
            : values.ccarClients?.map((a: MultiOptionType) => a.value) ?? webhook?.ccarClients,
        },
        {
          withCredentials: true,
        },
      );

      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    reset({});
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      title={webhook ? "Edit Webhook" : "New Webhook"}
      SlideProps={{
        onExited() {
          handleClose();
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          defaultValue={webhook?.name ?? ""}
          rules={{
            required: "Please enter a unique name for the webhook",
            maxLength: {
              value: 100,
              message: "Name must be less than 100 characters",
            },
            minLength: {
              value: 2,
              message: "Name must be at least 2 characters",
            },
            validate: (value: string) => {
              if (data?.find((d) => d.name === value && d.name !== webhook?.name)) {
                return "Name must be unique";
              }
              return true;
            },
          }}
          disabled={submitting || webhook !== null}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Name"
              autoFocus
              error={!!errors.name}
              helperText={errors.name?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="url"
          control={control}
          defaultValue={webhook?.url ?? ""}
          rules={{
            required: "Please enter a URL of the webhook",
            validate: (value: string) => {
              try {
                // eslint-disable-next-line no-new
                new URL(value);
                return true;
              } catch {
                return "Please enter a valid URL";
              }
            },
          }}
          disabled={submitting}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Url"
              type="url"
              error={!!errors.url}
              helperText={errors.url?.message}
              sx={{ marginTop: 4 }}
              {...field}
            />
          )}
        />

        <Controller
          name="alertIds"
          control={control}
          defaultValue={
            webhook?.alertIds?.map((a) => ({
              value: a,
              label: convertAlertIdToDisplayName(a),
            })) as MultiOptionType[]
          }
          rules={{
            validate: (value: MultiOptionType[]) => {
              if (!value?.length && !allAlertsEnabled) {
                return "Please select at least one alert";
              }
              return true;
            },
          }}
          disabled={submitting || allAlertsEnabled}
          render={({ field }) => (
            <CustomMultiReactSelect
              controlShouldRenderValue
              label="Alert IDs"
              placeholder="Select Alert IDs"
              options={alertIds.map((k) => ({
                value: k,
                label: convertAlertIdToDisplayName(k),
              }))}
              sx={{ marginTop: 4 }}
              error={!!errors.alertIds && !allAlertsEnabled}
              helperText={!allAlertsEnabled ? (errors.alertIds?.message as string) : ""}
              {...field}
            />
          )}
        />

        <Box display="flex" flexDirection="row" alignItems="center" sx={{ marginTop: 4 }}>
          <Controller
            name="allAlertsEnabled"
            control={control}
            defaultValue={webhook?.allAlertsEnabled}
            disabled={submitting}
            render={({ field }) => <Checkbox {...field} checked={field.value} />}
          />
          <Typography variant="body2" color="almostWhite.main">
            Enable the webhook for all alerts
          </Typography>
        </Box>

        <Controller
          name="ccarClients"
          control={control}
          defaultValue={
            webhook?.ccarClients?.map((a) => ({
              value: a,
              label: convertClientToDisplayName(a),
            })) as MultiOptionType[]
          }
          rules={{
            validate: (value: MultiOptionType[]) => {
              if (!value?.length && !allClientsEnabled) {
                return "Please select at least one protocol";
              }
              return true;
            },
          }}
          disabled={submitting || allClientsEnabled}
          render={({ field }) => (
            <CustomMultiReactSelect
              controlShouldRenderValue
              label="Protocols"
              placeholder="Select Protocols"
              options={Object.values(CcarClient).map((k) => ({
                value: k,
                label: convertClientToDisplayName(k as string),
              }))}
              sx={{ marginTop: 4 }}
              error={!!errors.ccarClients && !allClientsEnabled}
              helperText={!allClientsEnabled ? (errors.ccarClients?.message as string) : ""}
              {...field}
            />
          )}
        />

        <Box display="flex" flexDirection="row" alignItems="center" sx={{ marginTop: 4 }}>
          <Controller
            name="allClientsEnabled"
            control={control}
            defaultValue={webhook?.allClientsEnabled}
            disabled={submitting}
            render={({ field }) => <Checkbox {...field} checked={field.value} />}
          />
          <Typography variant="body2" color="almostWhite.main">
            Enable the webhook for all protocols
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: 8,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button color="primary" disabled={submitting} type="submit">
            {submitting && <CircularProgress color="inherit" size="24px" sx={{ marginRight: 2 }} />}
            {submitting ? "Saving" : "Save"}
          </Button>
        </Box>
      </form>
    </Drawer>
  );
};
