import { Box, Typography } from "@frontend/ui";
import { ShimmerLoader } from "@frontend/ui/shimmer-loader";
import { PieChart } from "@frontend/ui/charts";
import { useNodeOperatorsQuery } from "../../../../generated";

const TvrPieChart = () => {
  const { data, loading } = useNodeOperatorsQuery();
  const chartData = (data?.nodeOperators || []).map((o) => ({
    x: o.name,
    y: o.tvrEth,
  }));

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">Total Value Restaked by Node Operator</Typography>
      {loading ? (
        <ShimmerLoader sx={{ height: 288 }} />
      ) : (
        <PieChart data={chartData} format="number" labelSuffix="ETH" size={288} />
      )}
    </Box>
  );
};

export default TvrPieChart;
