import { DatumValue } from "@nivo/core";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import duration from "dayjs/plugin/duration";
import { RoundingMode } from "./types";

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

const isFraction = (num: number) => num.toString().includes(".");

export const getFractionDigits = (number: number, notation?: string, isPercent?: boolean): number => {
  const fractionalPart = Math.abs(number) - Math.floor(Math.abs(number));
  const fractionSignificantDigits = -Math.floor(Math.log10(fractionalPart % 1));

  switch (true) {
    case !isPercent && Math.abs(number) < 10 && notation === "compact":
      return Math.min(15, fractionSignificantDigits);
    case isPercent && Math.abs(number) < 1 && notation === "compact":
      return Math.min(15, fractionSignificantDigits);
    case Math.abs(number) < 10 && notation === "standard":
      return 3 + Math.min(15, fractionSignificantDigits);
    case notation === "compact" || Math.abs(number) < 100:
      return 2;
    case Math.abs(number) < 1000:
      return 1;
    default:
      return 0;
  }
};

export const formatAmount = (
  amount: number,
  {
    isPercent,
    currency,
    notation = "compact",
    minimumFractionDigits = 0,
    maximumFractionDigits = isPercent
      ? getFractionDigits(amount * 100, notation, isPercent)
      : getFractionDigits(amount, notation),
    roundingMode = "halfExpand",
  }: {
    isPercent?: boolean;
    currency?: string;
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    notation?: "compact" | "standard" | "scientific" | "engineering" | undefined;
    roundingMode?: RoundingMode;
  } = {},
) =>
  new Intl.NumberFormat("en-US", {
    style: isPercent ? "percent" : currency && "currency",
    notation: Math.abs(amount) <= Number.MAX_SAFE_INTEGER ? notation : "scientific",
    currency,
    maximumFractionDigits,
    minimumFractionDigits,

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - TODO: remove this when typescript version is updated - need these changes https://github.com/microsoft/TypeScript/pull/56902/files
    roundingMode,
  }).format(amount);

export const millisToMinutesAndSeconds = (millis: number): string => {
  const hours = Math.floor(millis / 3600000);
  const minutes = Math.floor((millis % 3600000) / 60000);
  const seconds = +((millis % 60000) / 1000).toFixed(0);
  // ES6 interpolated literals/template literals
  // If seconds is less than 10 put a zero in front.
  return `${hours}:${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export function formatCurrency(value: number, currency = "USD"): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value > 0.01 ? 2 : value > 0.00001 ? 5 : 8,
  });
  return formatter.format(value);
}

export const formatCurrencyStringAmount = (amount: string) =>
  formatAmount(Number(amount.replaceAll(",", "")), { currency: "USD" });

export const formatLongFormDate = (date: string): string => dayjs(date).format("MMMM DD, YYYY");

// https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
export const formatCsvDate = (date?: Date): string => dayjs(date).format("YYYY-MM-DD HH:mm:ss");

export const formatDate = (date?: Date, isFullDate?: boolean): string => {
  if (!date) return "";
  const dayjsDate = dayjs(date);
  const today = dayjsDate.isToday() && "Today";
  const yesterday = dayjsDate.isYesterday() && "Yesterday";
  const actualDate = date.toLocaleString(userLocale, { year: "numeric", month: "2-digit", day: "2-digit" });
  if (isFullDate) return actualDate;
  return `${today || yesterday || actualDate}`;
};

export const formatDay = (date?: Date, isFullDate?: boolean): string => {
  if (!date) return "";
  const dayjsDate = dayjs(date);
  const today = dayjsDate.isToday() && "Today";
  const yesterday = dayjsDate.isYesterday() && "Yesterday";
  const actualDate = date.toLocaleString(userLocale, { month: "2-digit", day: "2-digit" });
  if (isFullDate) return actualDate;
  return `${today || yesterday || actualDate}`;
};

export const formatTimeInDate = (date?: Date | string, withSeconds = true): string => {
  if (!date) return "";
  return date.toLocaleString(userLocale, {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
    second: withSeconds ? "2-digit" : undefined,
  });
};

export const formatDateAndTime = (date?: Date, withSeconds = true): string => {
  if (!date) return "";
  return `${formatDate(date)} at ${formatTimeInDate(date, withSeconds)}`;
};

export const formatDateOrTimeToday = (date?: Date, withSeconds = true): string => {
  if (!date) return "";
  const dayjsDate = dayjs(date);

  const isTodayDate = dayjsDate.isToday();

  return isTodayDate ? formatTimeInDate(date, withSeconds) : formatDateAndTime(date, withSeconds);
};

export const diffDates = (date0?: Date, date1?: Date) => {
  const day1 = dayjs(date0);
  const day2 = dayjs(date1);

  let hours = day2.diff(day1, "hours");
  const days = Math.floor(hours / 24);
  hours -= days * 24;

  return `${days}d ${hours}H`;
};

export const formatCamelCaseToPascalCase = (value?: string) => {
  if (value) {
    const withSpacings = value.replace(/([a-z])([A-Z])/g, "$1 $2");
    return withSpacings.charAt(0).toUpperCase() + withSpacings.slice(1);
  }
  return value;
};

export const formatDuration = ({
  milliseconds,
  notation = "standard",
  showSeconds = true,
  compactHours = 1,
  showHighestUnitOnly,
  zeroUnit = "s",
}: {
  milliseconds: number;
  notation?: "standard" | "compact";
  showSeconds?: boolean;
  compactHours?: number;
  showHighestUnitOnly?: boolean;
  zeroUnit?: "H" | "s";
}): string => {
  const dur = dayjs.duration(milliseconds);
  const days = Math.floor(dur.asDays());
  const hours = Math.floor(dur.asHours() - 24 * days);
  const minutes = Math.floor(dur.asMinutes() - 60 * hours);
  const seconds = Math.floor(dur.asSeconds() - 60 * minutes);
  const compactUnits = [
    { unit: "d", value: days },
    { unit: "H", value: hours },
  ];

  const timeUnits =
    notation === "compact" || dur.asHours() > compactHours
      ? compactUnits
      : [
          { unit: "d", value: days },
          { unit: "H", value: hours },
          { unit: "m", value: minutes },
          ...(showSeconds ? [{ unit: "s", value: seconds }] : []),
        ];

  const formattedArr = timeUnits.filter((u) => u.value > 0).map((u) => `${u.value}${u.unit}`);

  return (showHighestUnitOnly ? formattedArr[0] : formattedArr.join(" ")) || `0${zeroUnit}`;
};

export const formatPercent = (
  value: number,
  { minimumFractionDigits = 0, maximumFractionDigits = 2 } = { minimumFractionDigits: 0, maximumFractionDigits: 2 },
): string =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);

export const formatPercentDecimal = (percent: number) =>
  formatPercent(percent / 100, {
    minimumFractionDigits: !isFraction(percent) ? 0 : 2,
  });

/**
 * Format percent value, appending `+` before positive values
 */
export const formatDeltaPercent = (percent: number) => {
  let x = formatPercent(percent);
  if (percent > 0) {
    x = `+${x}`;
  }
  return x;
};

type FormatAmountProps = {
  isPercent?: boolean;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  notation?: "compact" | "standard" | "scientific" | "engineering" | undefined;
  roundingMode?: RoundingMode | undefined;
};

export const formatNumericValue = (
  amount: number | string,
  props: FormatAmountProps = {},
  minValue?: number,
): string => {
  const isString = typeof amount === "string";
  const parsed = isString ? parseFloat(amount) : amount;
  if (Number.isNaN(parsed)) {
    return amount.toString();
  }

  if (parsed && minValue && parsed < minValue) {
    return `< ${formatAmount(minValue, { ...props, minimumFractionDigits: undefined, maximumFractionDigits: undefined, notation: "standard" })}`;
  }
  return formatAmount(parsed, props);
};

export const formatAxis = (
  value: DatumValue,
  maximumFractionDigits = 4,
  currency?: string,
  isPercent?: boolean,
  minimumFractionDigits?: number,
) =>
  formatAmount(Number(value) || 0, {
    isPercent,
    minimumFractionDigits,
    maximumFractionDigits,
    notation: "compact",
    currency,
  });

export const formatAddressCompact = (address: string, length = 6) =>
  `${address.substring(0, length)}...${address.substring(address.length - length)}`;
export const formatStringCompact = (str: string, length = 15) => {
  if (str.length <= length) return str;
  return `${str.substring(0, length)}...`;
};

export const extractNumberFromString = (s: string) => Number(s.replace(/[^\d.]/g, ""));

export const toTitleCase = (str: string) =>
  str.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
